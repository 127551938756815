import Http from '@/http'

export default {
    getInstitutions ({ commit }) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get('/institutions').then((response) => {
                commit('SET_INSTITUTIONS', response.data.data)
                commit('SET_INSTITUTIONS_IS_REGISTERED', true)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}
