import _ from 'lodash'

export default {
    getBasketByResidentId: (state) => (residentId) =>  {
        return _.find(state.dataMultiBasket,  (basket) => {
            if (basket.resident_id == residentId) {
                return true
            }
            return false
        })
    }
}
