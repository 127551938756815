export default {
    loaded: {
        product: false,
        service: false
    },
    categories: {
        product: [],
        service: []
    }
}
