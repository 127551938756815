import _ from 'lodash'
import Http from '@/http'

import {
    computeShoppingListServiceTotalAmount2
} from '@/modules/services'

export default {
    getPcpById: state => (pcpId) => {
        return _.find(state.pcps, (pcp) => {
            return pcp.id == pcpId
        })
    },
    getPcpIndexById: state => (pcpId) => {
        return _.findIndex(state.pcps, (pcp) => {
            return pcp.id == pcpId
        })
    },
    getResidentPcp: (state) => (residentId) =>  {
        return _.find(state.pcps, (pcp) => {
            return pcp.residentId == residentId
        })
    },
    getPcpCount: (state, getters) => (residentId) =>  {
        const pcp = getters.getResidentPcp(residentId)
        if (!pcp) {
            return 0
        }
        const productCount = _.sumBy(pcp.shoppingListProducts, (product) => {
            return product.quantity
        })
        const serviceCount = _.sumBy(pcp.shoppingListServices, (service) => {
            return service.quantity
        })
        return productCount + serviceCount
    },
    getPcpAmount: (state, getters) => (residentId) =>  {
        const pcp = getters.getResidentPcp(residentId)
        if (!pcp) {
            return 0.0
        }
        const productAmount = _.sumBy(pcp.shoppingListProducts, (product) => {
            return product.quantity * _.get(product, 'meta.offer.price', 0)
        })
        const serviceAmount = _.sumBy(pcp.shoppingListServices, (service) => {
            return service.quantity * computeShoppingListServiceTotalAmount2(service)
        })
        return (productAmount + serviceAmount).toFixed(2)
    },
    getQuantity: (state, getters) => (residentId, productId, type) => {
        const pcp = getters.getResidentPcp(residentId)
        if (!pcp) {
            return 0
        }
        var quantity = 0
        switch (type) {
            case 'product':
                const productFound = _.find(pcp.shoppingListProducts, (shoppingProduct) => {
                    return productId == _.get(shoppingProduct, 'meta.product.productSku', '')
                })
                if (productFound) {
                    quantity = productFound.quantity
                }
                break
            case 'service':
                const serviceFound = _.find(pcp.shoppingListServices, (shoppingService) => {
                    return productId == _.get(shoppingService, 'meta.serviceModel.code', '')
                })
                if (serviceFound) {
                    quantity = serviceFound.quantity
                }
                break
        }
        return quantity
    }
}
