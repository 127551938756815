import _ from 'lodash'

export default {
    SET_CALENDAR_ITEMS (state, payload) {
        state.calendarItems = payload
    },
    SET_CALENDAR_ITEM (state, payload) {
        const calendarItem = _.get(payload, 'calendarItem', {})
        state.calendarItems = state.calendarItems.map((item) => {
            if (item.id === calendarItem.id) {
                return calendarItem
            }
            return item
        })
    },
    SET_ACCEPTABLE_CALENDAR_ITEMS (state, payload) {
        state.acceptableCalendarItems = payload
    },
    SET_ACCEPTABLE_CALENDAR_ITEM (state, payload) {
        const calendarItem = _.get(payload, 'calendarItem', {})
        state.acceptableCalendarItems = state.acceptableCalendarItems.map((item) => {
            if (item.id === calendarItem.id) {
                return calendarItem
            }
            return item
        })
    },
    SET_CONSUMABLE_CALENDAR_ITEMS (state, payload) {
        state.consumableCalendarItems = payload
    },
    SET_CONSUMABLE_CALENDAR_ITEM (state, payload) {
        const calendarItem = _.get(payload, 'calendarItem', {})
        state.consumableCalendarItems = state.consumableCalendarItems.map((item) => {
            if (item.id === calendarItem.id) {
                return calendarItem
            }
            return item
        })
    }
}
