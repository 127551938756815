import state from './forms-state.js'
import mutations from './forms-mutations.js'
import actions from './forms-actions.js'
import getters from './forms-getters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
