import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' // Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
Vue.use(Vuesax)

import UILibrary, { getVueOptions } from '@happytal/bo-ui-library'
import '@happytal/bo-ui-library/dist/bo-ui-library.css'
import './assets/css/vuetify-reset.css'
import './assets/scss/bo-ui-library/_fixes.scss'
Vue.use(UILibrary)

// Vue-toasted
import Toasted from 'vue-toasted'
Vue.use(Toasted, {})

// Axios
import Http from '@/axios'
Vue.prototype.$http = Http

// Theme Configurations
import '../themeConfig.js'

// ACL
import acl from './acl'

// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'

// Tailwind
import '@/assets/css/main.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// i18n
import i18n from './i18n/i18n'

// Clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

// Tour
import VueTour from 'vue-tour'
Vue.use(VueTour)
require('vue-tour/dist/vue-tour.css')

// VeeValidate
import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)
VeeValidate.Validator.extend('verify_password', {
    getMessage: field => `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
    validate: value => {
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\(\)\,\.¥$€;!_-])(?=.{8,})");
        return strongRegex.test(value)
    }
})

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective)

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// VueObserveVisibility
import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

// Import happytal custom Styles
import '@/assets/scss/happytal.scss'
import '@/assets/scss/helpers.scss'

import aclChecker from '@/modules/acl.js'
Vue.use((_Vue, options) => {
    _Vue.mixin({
        beforeCreate () {
            this.$aclChecker = aclChecker
        }
    })
})

const host = window.location.hostname
const gtmEnabled = host != 'localhost'
console.log('gtm:enabled', gtmEnabled)

import VueGtm from '@gtm-support/vue2-gtm'
Vue.use(VueGtm, {
    id: 'GTM-N6H2X7P',
    defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
    enabled: gtmEnabled, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    //ignoredViews: ['homepage'], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    acl,
    ...getVueOptions({
        theme: {
          primaryColor: '#13d3c3',
        },
      }),
    render: h => h(App)
}).$mount('#app')
