import Http from '@/http'

export default {
    /*getResidents ({ commit }, payload) {
        return new Promise((resolve, reject) => {
              Http.apis.static.get('/residents.json').then((response) => {
                  commit('SET_RESIDENTS', response.data.residents)
                  resolve(response)
              })
              .catch((error) => {
                  reject(error)
              })
        })
    }*/
    getResidents ({ commit }, payload) {
        return new Promise((resolve, reject) => {
              Http.apis.ehpad.get(`/users/${payload.userId}/residents?InstitutionId=${payload.institutionId}`).then((response) => {
                  commit('SET_RESIDENTS', response.data.residents)
                  commit('SET_RESIDENTS_IS_REGISTERED', true)
                  resolve(response)
              })
              .catch((error) => {
                  reject(error)
              })
        })
    },
    getResidentDeactivateStatus ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const residentId = payload.residentId
            const userId = payload.userId
            Http.apis.ehpad.post(`/residents/${residentId}/deactivate-status`, {
                userId
            })
            .then((response) => {
                resolve(response.data || {})
            })
            .catch(reject)
        })
    },
    deactivateResident ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const residentId = payload.residentId
            const userId = payload.userId
            Http.apis.ehpad.put(`/residents/${residentId}/deactivate`, {
                userId
            })
            .then((response) => {
                commit('DELETE_RESIDENT', {
                    residentId
                })
                resolve(response)
            })
            .catch(reject)
        })
    },
    updateTutor ({ commit }, payload) {
        return new Promise((resolve, reject) => {
              Http.apis.ehpad.put(`/residents/${payload.residentId}/user-in-charge`, {
                  userIdInChargeOfResident: payload.tutor ? payload.tutor.id : null
              }).then((response) => {
                  commit('UPDATE_TUTOR', payload)
                  resolve(response)
              })
              .catch((error) => {
                  reject(error)
              })
        })
    },
    updateResident ({ commit }, payload) {
        return new Promise((resolve, reject) => {
              const residentToSend = _.omit(payload.resident, [
                  'id',
                  'institutionId',
                  'isNew',
                  'userInChargeOfResident',
                  'stripePaymentMethod',
                  'stripeCustomerId',
                  'hasShoppingListFilled',
                  'externalPspId',
                  'createdOnUtc',
                  'updatedOnUtc'
              ])
              Http.apis.ehpad.put(`/residents/${payload.resident.id}`, {
                  resident: residentToSend
              }).then((response) => {
                  commit('UPDATE_RESIDENT', payload.resident)
                  resolve(response)
              })
              .catch((error) => {
                  reject(error)
              })
        })
    },
    incrementResidentOpenedSuggestionCount({ commit }, residentId) {
        commit('INCREMENT_RESIDENT_OPENED_SUGGESTION_COUNT', residentId)
    },
    decrementResidentOpenedSuggestionCount({ commit }, residentId) {
        commit('DECREMENT_RESIDENT_OPENED_SUGGESTION_COUNT', residentId)
    },
    createResident ({ commit }, payload) {
        return new Promise((resolve, reject) => {
              Http.apis.ehpad.post(`/residents`, {
                  resident: payload.resident
              }).then((response) => {
                  const resident = response.data.resident
                  commit('ADD_RESIDENT', resident)
                  resolve(resident)
              })
              .catch((error) => {
                  reject(error)
              })
        })
    },
    updateCurrentResidentId ({ commit }, residentId) {
        commit('UPDATE_CURRENT_RESIDENT_ID', residentId)
    }
}
