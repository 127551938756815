import state from './residents-state.js'
import mutations from './residents-mutations.js'
import actions from './residents-actions.js'
import getters from './residents-getters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
