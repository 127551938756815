import Http from '@/http'

import appsettings from '@/appsettings'

const vueAppPictureStatic = appsettings.VUE_APP_PICTURE_STATIC

export default {
    /*getCategories ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/catalogs/${payload.institutionId}?provider=WebSite`).then((response) => {
                const locale = payload.locale ? payload.locale : 'fr'
                const i18nItems = response.data.localizedProperties
                const categories = response.data.categories
                var storeCategories = categories.map((category) => {
                    category.name = getI18nValue(i18nItems, `${locale}.${removeRole(category.name)}`)
                    category.description = getI18nValue(i18nItems, `${locale}.${removeRole(category.description)}`)
                    category.fullDescription = getI18nValue(i18nItems, `${locale}.${removeRole(category.fullDescription)}`)
                    category.picturesUrls = category.picturesUrls.map((url) => {
                        return `${vueAppPictureStatic}${url}`
                    })
                    return category
                })
                commit('SET_CATEGORIES', storeCategories)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }*/
    getProductCategories ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/front-categories?application=happydom&type=product&institutionId=${payload.institutionId}`).then((response) => {
                commit(
                    'SET_PRODUCT_CATEGORIES',
                    _.get(response, 'data.categories', [])
                )
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getServiceCategories ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/front-categories?application=happydom&type=service&institutionId=${payload.institutionId}`).then((response) => {
              commit(
                  'SET_SERVICE_CATEGORIES',
                  _.get(response, 'data.categories', [])
              )
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}
