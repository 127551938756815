export default {
    Guest: 'Guest',
    EhpadEmployee: 'EhpadEmployee',
    HappytalEmployee: 'HappytalEmployee',
    Tutor: 'Tutor',
    Family: 'Family',
    EhpadManager: 'EhpadManager',
    HappytalSupport: 'HappytalSupport',
    Provider: 'Provider',
    HappytalProvider: 'HappytalProvider'
}
