import _ from 'lodash'

import Storage from '@/storage'

export default {
    SAVE_DRAFTS(state, payload) {
        Storage.setDrafts(state.drafts)
    },
    SET_POPUP_VISIBLE(state, payload) {
        state.popup.visible = payload.visible
        state.popup.product = state.popup.visible ? payload.product : null
    },
    CREATE_DRAFT(state, payload) {
        const draft = {
            id: payload.draftId,
            userId: payload.userId,
            residentId: payload.residentId,
            type: payload.type,
            products: []
        }
        state.drafts.push(draft)
        if (payload.callback) {
            payload.callback(draft)
        }
    },
    DELETE_USER_DRAFTS(state, payload) {
        _.remove(state.drafts, (draft) => {
            return draft.userId === payload.userId
        })
    },
    ADD_OR_UPDATE_PRODUCT(state, payload) {
        const draftIndex = _.findIndex(state.drafts, (draft) => {
            return draft.id === payload.draftId
        })
        const draft = state.drafts[draftIndex]
        const productIndex = _.findIndex(draft.products, (product) => {
            return product.id === payload.product.id
        })
        if (productIndex > -1) {
            if (payload.increaseQuantity) {
                const oldProduct = state.drafts[draftIndex].products[productIndex]
                payload.product.quantity += oldProduct.quantity
            }
            state.drafts[draftIndex].products[productIndex] = payload.product
        } else {
            state.drafts[draftIndex].products.push(payload.product)
        }
    },
    DELETE_PRODUCT(state, payload) {
        const draftIndex = _.findIndex(state.drafts, (draft) => {
            return draft.id === payload.draftId
        })
        const draft = state.drafts[draftIndex]
        const productIndex = _.findIndex(draft.products, (product) => {
            return product.id === payload.product.id
        })
        if (productIndex > -1) {
            state.drafts[draftIndex].products.splice(productIndex, 1)
        }
    },
    UPDATE_BASKET_ORDER(state, payload) {
        let newDraft = []
        payload.basket.basketLineProducts.forEach((product) => {
            if (payload.draft.products.length > 0) {
                let productToAdd = _.find(payload.draft.products, (productQuote) => {
                    if(productQuote.type === "product") {
                    return productQuote.meta.offer.offerId === product.offerId
                    }
                })
                if(productToAdd) {
                    productToAdd.quantity = product.quantity
                    newDraft.push(productToAdd)
                }
            }
        })

        let reOrderNewDraft = _.orderBy(newDraft, function(e) {
            return parseInt(e.meta.offer.offerId);
        }, ['asc']);

        payload.basket.basketLineServices.forEach((service) => {
            if (payload.draft.products.length > 0) {
                let productToAdd = _.find(payload.draft.products, (productQuote) => {
                    if(productQuote.type === "service") {
                        return productQuote.id === service.serviceCode
                    }
                })
                if(productToAdd) {
                    productToAdd.quantity = service.quantity
                    reOrderNewDraft.push(productToAdd)
                }
            }
        })

        const draftIndex = _.findIndex(state.drafts, (draftIndex) => {
            return draftIndex.id === payload.draft.id
        })
        state.drafts[draftIndex].products = reOrderNewDraft
        Storage.setDrafts(state.drafts)
    },
}
