import _ from 'lodash'

export default {
    getCategoryById: state => (categoryId) => {
        return _.find(state.categories, (category) => {
            return category.id == categoryId
        })
    },
    getProductCategoryById: state => (categoryId) => {
        return _.find(state.categories.product, (category) => {
            return category.id == categoryId
        })
    },
    getRootCategory: (state, getters) => (categoryId) => {
        var category = null
        // Ignore happytal root category (id:1000,label:Acceuil)
        while (categoryId && categoryId != 1000) {
            category = getters.getCategoryById(categoryId)
            if (!category) {
                return null
            }
            categoryId = category.parentCategoryId
        }
        return category
    },
    getSubCategories: (state) => (categoryId, sorted, sortTypes) => {
        if (!categoryId) {
            return null
        }
        var categories = _.get(state, 'categories', [])
        categories = _.filter(categories, (category) => {
            return category.parentCategoryId == categoryId
        })
        if (!sorted) {
            return categories
        }
        return _.orderBy(categories, (category) => {
            const name = category.name || ''
            return name.toLowerCase()
        }, sortTypes)
    }
}
