import Http from '@/http'

import {
    computeShoppingListServiceTotalAmount
} from '@/modules/services'

const noDataFoundError = 'No data found from ShoppingListAPI'

export default {
    /*getPcps ({ commit }) {
        return new Promise((resolve, reject) => {
            Http.apis.static.get('/pcps.json').then((response) => {
                commit('SET_PCPS', response.data.pcps)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }*/
    getResidentPcp ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/shopping-lists`, {
                params: {
                    residentId: payload.residentId
                }
            })
            .then((response) => {
                const data = response.data
                if (!data) {
                    reject(noDataFoundError)
                    return
                }
                var list = data.shoppingList || {}
                list.variantData = data.variantData
                list.errors = {
                    products: [],
                    services: []
                }
                var productList = _.map(list.shoppingListProducts, (shoppingProduct) => {
                    var finalProduct = _.omit(shoppingProduct, [
                        'productSku',
                        'title',
                        'createdOnUtc',
                        'updatedOnUtc',
                        'shopId',
                        'shopName'
                    ])
                    var offerFound = {}
                    const productFound = _.find(data.products, (product) => {
                        return _.find(product.offers, (offer) => {
                            if (offer.offerId == finalProduct.offerId) {
                                offerFound = offer
                                return true
                            }
                            return false
                        })
                    })
                    if (!productFound) {
                        list.errors.products.push({
                            offerId: finalProduct.offerId
                        })
                    }
                    finalProduct.meta = {
                        product: productFound ? productFound.product : null,
                        offer: offerFound,
                        validated: true
                    }
                    return finalProduct
                })
                list.shoppingListProducts = _.filter(productList, (shoppingProduct) => {
                    return shoppingProduct.meta.product != null
                })
                var serviceList = _.map(list.shoppingListServices, (shoppingService) => {
                    var finalService = _.omit(shoppingService, [
                        'serviceModelCode',
                        'title',
                        'createdOnUtc',
                        'updatedOnUtc',
                        'shopId',
                        'shopName'
                    ])
                    var offerFound = {}
                    const serviceFound = _.find(data.services, (service) => {
                        return _.find(service.services, (offer) => {
                            if (offer.code == finalService.serviceCode) {
                                offerFound = offer
                                return true
                            }
                            return false
                        })
                    })
                    if (!serviceFound) {
                        list.errors.services.push({
                            serviceCode: finalService.serviceCode
                        })
                    }
                    finalService.meta = {
                        serviceModel: serviceFound ? serviceFound.serviceModel : null,
                        service: offerFound,
                        price: computeShoppingListServiceTotalAmount(shoppingService, data.services),
                        validated: true
                    }
                    return finalService
                })
                list.shoppingListServices = _.filter(serviceList, (shoppingService) => {
                    return shoppingService.meta.serviceModel != null
                })

                commit('ADD_PCP', {
                    pcp: _.omit(list, 'errors')
                })
                resolve(list)
            })
            .catch(reject)
        })
    },
    createPcp ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.post(`/shopping-lists`, {
                residentId: payload.residentId
            })
            .then((response) => {
                const data = response.data
                if (data) {
                    var list = data.shoppingList || {}
                    commit('ADD_PCP', {
                        pcp: list
                    })
                    resolve(list)
                }
                else {
                    reject(noDataFoundError)
                }
            })
            .catch(reject)
        })
    },
    updatePcp ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const pcpId = payload.pcpId
            const products = _.map(payload.products, (product) => {
                return _.omit(product, [
                    'meta'
                ])
            }).filter(product => product.quantity > 0)
            const services = _.map(payload.services, (service) => {
                return _.omit(service, [
                    'meta'
                ])
            }).filter(service => service.quantity > 0)
            Http.apis.ehpad.put(`/shopping-lists/${pcpId}`, {
                shoppingListProducts: products,
                shoppingListServices: services
            })
            .then((response) => {
                /*commit('UPDATE_PCP', {
                    pcpId: pcpId,
                    products: products
                })*/
                resolve(response)
            })
            .catch(reject)
        })
    },
    async mergePcp ({ dispatch }, { residentId, pcp, products, services }) {
        if (!pcp) {
            pcp = await dispatch('createPcp', { residentId })
        }
        const newProductOfferIds = products.map(product => product.meta.offer.offerId)
        const newServiceCodes = services.map(service => service.meta.service.code)
        const mergedPcp = {
            ...pcp,
            shoppingListProducts: [
                ...pcp.shoppingListProducts.filter(product => !newProductOfferIds.includes(product.offerId)),
                ...products.map(product => ({
                    frequencyInMonth: product.frequency,
                    offerId: product.meta.offer.offerId,
                    quantity: product.quantity,
                    unitPrice: product.price
                }))
            ],
            shoppingListServices: [
                ...pcp.shoppingListServices.filter(service => !newServiceCodes.includes(service.serviceCode)),
                ...services.map(service => ({
                    frequencyInMonth: service.frequency,
                    options: service.meta.options,
                    quantity: service.quantity,
                    serviceCode: service.meta.service.code,
                    unitPrice: service.price
                }))
            ]
        }
        await Http.apis.ehpad.put(`/shopping-lists/${pcp.id}`, mergedPcp)
    },
    deletePcp ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const pcpId = payload.pcpId
            const userId = payload.userId
            Http.apis.ehpad.post(`/shopping-lists/${pcpId}/delete`, {
                userId
            })
            .then((response) => {
                commit('DELETE_PCP', {
                    pcpId
                })
                resolve(response)
            })
            .catch(reject)
        })
    },
    addProduct ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const storeProduct = payload.storeProduct
            const pcpProduct = payload.pcpProduct
            Http.apis.ehpad.put(`/shopping-lists/${payload.pcpId}/products/${pcpProduct.productId}`, {
                quantity: pcpProduct.quantity,
                frequencyInMonth: pcpProduct.frequencyInMonth,
                ManufacturerId: storeProduct.manufacturerId
            })
            .then((response) => {
                commit('ADD_PRODUCT', {
                    pcpId: payload.pcpId,
                    product: pcpProduct
                })
                resolve(response)
            })
            .catch(reject)
        })
    },
    updateProductQuantity ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const storeProduct = payload.storeProduct
            const pcpProduct = payload.pcpProduct
            const newQuantity = payload.quantityToAdd + pcpProduct.quantity
            Http.apis.ehpad.put(`/shopping-lists/${payload.pcpId}/products/${pcpProduct.productId}`, {
                quantity: newQuantity,
                frequencyInMonth: pcpProduct.frequencyInMonth,
                ManufacturerId: storeProduct.manufacturerId
            })
            .then((response) => {
                commit('UPDATE_PRODUCT_QUANTITY', {
                    pcpId: payload.pcpId,
                    productId: pcpProduct.productId,
                    quantity: newQuantity
                })
                resolve(response)
            })
            .catch(reject)
        })
    },
    updateProductFrequency ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const storeProduct = payload.storeProduct
            const pcpProduct = payload.pcpProduct
            Http.apis.ehpad.put(`/shopping-lists/${payload.pcpId}/products/${pcpProduct.productId}`, {
                quantity: pcpProduct.quantity,
                frequencyInMonth: payload.frequency,
                ManufacturerId: storeProduct.manufacturerId
            })
            .then((response) => {
                commit('UPDATE_PRODUCT_FREQUENCY', {
                    pcpId: payload.pcpId,
                    productId: pcpProduct.productId,
                    frequencyInMonth: payload.frequency
                })
                resolve(response)
            })
            .catch(reject)
        })
    },
    deleteProduct ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const storeProduct = payload.storeProduct
            const pcpProduct = payload.pcpProduct
            Http.apis.ehpad.delete(`/shopping-lists/${payload.pcpId}/products/${pcpProduct.productId}`)
            .then((response) => {
                commit('DELETE_PRODUCT', {
                    pcpId: payload.pcpId,
                    productId: pcpProduct.productId
                })
                resolve(response)
            })
            .catch(reject)
        })
    }
}
