function urlGetParameter (name) {
    var result = null
    var tmp = []
    var items = location.search.substr(1).split('&')
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split('=')
        if (tmp[0] === name) {
            result = decodeURIComponent(tmp[1])
        }
    }
    return result
}
function getRequestUrl (params) {
    var url = '?'
    Object.keys(params).forEach((key) => {
        const value = encodeURIComponent(params[key])
        url += `${key}=${value}&`
    })
    return url.slice(0, -1)
}

export {
    urlGetParameter,
    getRequestUrl
}
