import Routes from '@/constants/routes'
import Http from "@/http"

import { Commit } from 'vuex/types'
import { IFrontCategoryDto } from '@happytal/ecommerce-api'
import { CatalogMenuItem } from '@/types/components'

interface CatalogCategoriesState {
    categories: IFrontCategoryDto[]
    categoriesLoaded: boolean
}

function transformCategoryDataForMenu(category: IFrontCategoryDto, level: number, isMobile: boolean): CatalogMenuItem {
    const { id, name, subCategories } = category
    let subItems: CatalogMenuItem[] = []
    let route = {
        name: Routes.ResidentCatalog,
        params: {
            category_id: id ?? ''
        }
    } as any
    if (subCategories?.length && level < 3) {
        subItems = subCategories.map(subCategory => transformCategoryDataForMenu(subCategory, level + 1, isMobile))
        if (isMobile) {
            subItems.unshift({
                id: id ?? '',
                title: 'Afficher tout',
                level: level + 1,
                route,
                subItems: []
            } as CatalogMenuItem)
            route = undefined
        }
    }
    return {
        id: id ?? '',
        title: name ?? '',
        route,
        level,
        subItems,
    }
}

function sortCategories(categories: IFrontCategoryDto[]): IFrontCategoryDto[] {
    const categoriesMapped = categories.map(
        category => Object.assign(
            category,
            { subCategories: category.subCategories?.length ? sortCategories(category.subCategories) : [] }
        )
    )
    return categoriesMapped.sort((a: IFrontCategoryDto, b: IFrontCategoryDto) => {
        const aDisplayOrder = a?.displayOrder || 0
        const bDisplayOrder = b?.displayOrder || 0
        return aDisplayOrder - bDisplayOrder
    })
}

function findCategoryById(categories: IFrontCategoryDto[], id: string | undefined): IFrontCategoryDto | undefined {
    let categoryFinded: IFrontCategoryDto | undefined = undefined
    for (const category of categories) {
        if (category.id === id) {
            categoryFinded = category
            break
        }
        else if (category.subCategories?.length) {
            const subCategoryFinded = findCategoryById(category.subCategories, id)
            if (subCategoryFinded) {
                categoryFinded = subCategoryFinded
                break
            }
        }
    }
    return categoryFinded
}

function findDefaultCategory(categories: IFrontCategoryDto[]): IFrontCategoryDto | undefined {
    let categoryFinded: IFrontCategoryDto | undefined = undefined
    for (const category of categories) {
        if (category.isDefault) {
            categoryFinded = category
            if (category.subCategories?.length) {
                const subCategoryFinded = findDefaultCategory(category.subCategories)
                if (subCategoryFinded) {
                    categoryFinded = subCategoryFinded
                    break
                }
            }
            break
        }
    }
    if (categoryFinded && !categoryFinded.parentId && categoryFinded.subCategories?.[0]) {
        categoryFinded = categoryFinded.subCategories[0]
    }
    return categoryFinded
}

function getCategoryPathById(categories: IFrontCategoryDto[], id: string): string[] | any {
    let category = findCategoryById(categories, id)
    if (!category) {
        return []
    }
    const resultPath = [category]
    do {
        category = findCategoryById(categories, category?.parentId)
        if (category) {
            resultPath.push(category)
        }
    }
    while (category && category.parentId)
    return resultPath.reverse()
}

export default {
    isRegistered: false,

    namespaced: true,

    state: {
        categories: [],
        categoriesLoaded: false
    } as CatalogCategoriesState,

    getters: {
        getCategoriesForMainNav: (state: CatalogCategoriesState) => (isMobile: boolean) => {
            if (!state.categories.length) {
                return []
            }
            return state.categories.map(category => transformCategoryDataForMenu(category, 0, isMobile))
        },
        getCategoryById: (state: CatalogCategoriesState) => (id: string) => {
            return findCategoryById(state.categories, id)
        },
        getDefaultCategory: (state: CatalogCategoriesState) => {
            return findDefaultCategory(state.categories)
        },
        getCategoryPathById: (state: CatalogCategoriesState) => (id: string) => {
            return getCategoryPathById(state.categories, id)
        }
    },

    mutations: {
        SET_CATEGORIES(state: CatalogCategoriesState, categories: IFrontCategoryDto[]) {
            state.categories = categories
        },
        SET_CATEGORIES_LOADED(state: CatalogCategoriesState, value: boolean) {
            state.categoriesLoaded = value
        },
    },

    actions: {
        async getCategories({ commit }: { commit: Commit }, { institutionId } : { institutionId: number }) {
            try {
                const categoriesResponse = await Http.apis.ehpad.get(`/front-categories/v2?application=happydom&institutionId=${institutionId}`)
                const categories = categoriesResponse?.data?.categories
                const categoriesSorted = sortCategories(categories)
                if (categoriesSorted.length) {
                    commit('SET_CATEGORIES', categoriesSorted)
                }
                commit('SET_CATEGORIES_LOADED', true)
            }
            catch (error: any) {
                return new Error(`Une erreur s'est produite pendant le chargement des categories.`)
            }
        },
    }
}
