import _ from 'lodash'
import Axios from 'axios'
import Storage from '@/storage'
import Http from '@/http'
import Apis from '@/constants/apis'
import appsettings from '@/appsettings'

const vueStaticApi = appsettings.VUE_APP_STATIC_API
const vueEhpadApi = appsettings.VUE_APP_EHPAD_API
const vueStrapiApi = appsettings.VUE_APP_STRAPI_API

Http.addApi(Apis.Static, Axios.create({
    baseURL: vueStaticApi
}))
Http.addApi(Apis.Ehpad, Axios.create({
    baseURL: vueEhpadApi
}))
Http.addApi(Apis.Strapi, Axios.create({
    baseURL: vueStrapiApi
}))
// For dashboard demo purposes
Http.addApi(Apis.Dashboard, Axios.create())

const user = Storage.getCurrentUser()
if (user) {
    const token = _.get(user, 'auth.access_token', null)
    if (token) {
        Http.setBearerToken(Apis.Ehpad, token)
    }
}

export default Http
