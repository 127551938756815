import { Commit } from 'vuex/types'

interface MainNavState {
    overrideNavByDefault: boolean
    overrideNavDisplay: boolean
}



export default {
    isRegistered: false,

    namespaced: true,

    state: { 
        overrideNavByDefault: false,
        overrideNavDisplay: false
    } as MainNavState,

    getters: {},

    mutations: {
        UPDATE_OVERRIDE_NAV_BY_DEFAULT(state: MainNavState, value: boolean) {
            state.overrideNavByDefault = value
        },
        UPDATE_OVERRIDE_NAV_DISPLAY(state: MainNavState, value: boolean) {
            state.overrideNavDisplay = value
        },
    },
    
    actions: {
        updateOverrideNavByDefault({ commit }: { commit: Commit }, value: boolean) {
            commit('UPDATE_OVERRIDE_NAV_BY_DEFAULT', value)
        },
        updateOverrideNavDisplay({ commit }: { commit: Commit }, value: boolean) {
            commit('UPDATE_OVERRIDE_NAV_DISPLAY', value)
        },
    }
}