import _ from 'lodash'

export default {
    getDraftById: state => (draftId) => {
        return _.find(state.drafts, (draft) => {
            return draft.id === draftId
        })
    },
    getDraftPopup: (state) => () => {
        return state.popup
    },
    getDrafts: (state) => (userId, residentId, draftType) => {
        return _.filter(state.drafts, (draft) => {
            if (draftType && draft.type !== draftType) {
                return false
            }
            if (userId && draft.userId !== userId) {
                return false
            }
            return draft.residentId === residentId
        })
    },
    getFirstDraft: (state) => (userId, residentId, draftType) => {
        return _.find(state.drafts, (draft) => {
            if (draftType && draft.type !== draftType) {
                return false
            }
            if (userId && draft.userId !== userId) {
                return false
            }
            return draft.residentId === residentId
        })
    },
    getProductDraft: (state) => (userId, residentId, productId) => {
        const drafts = _.filter(state.drafts, (draft) => {
            if (userId && draft.userId !== userId) {
                return false
            }
            return draft.residentId === residentId
        })
        return _.find(drafts, (draft) => {
            return _.find(draft.products, (product) => {
                return product.id === productId
            })
        })
    },
    getDraftAmount: (state, getters) => (userId, residentId, draftType, productType) => {
        const drafts = getters.getDrafts(userId, residentId, draftType)
        if (!drafts || drafts.length < 1) {
            return 0.0
        }
        let productAmount = 0
        drafts.forEach((draft) => {
            productAmount += _.sumBy(draft.products, (product) => {
                if (productType && product.type !== productType) {
                    return 0.0
                }
                return product.quantity * product.price
            })
        })
        return productAmount.toFixed(2)
    },
    getProductCount: (state, getters) => (userId, residentId, draftType, productType) => {
        const drafts = getters.getDrafts(userId, residentId, draftType)
        if (!drafts || drafts.length < 1) {
            return 0
        }
        let productCount = 0
        drafts.forEach((draft) => {
            productCount += _.filter(draft.products, (product) => {
                if (productType && product.type !== productType) {
                    return false
                }
                return true
            }).length
        })
        return productCount
    },
    getQuantitySum: (state, getters) => (userId, residentId, draftType, productType) => {
        const drafts = getters.getDrafts(userId, residentId, draftType)
        if (!drafts || drafts.length < 1) {
            return 0
        }
        let quantityCount = 0
        drafts.forEach((draft) => {
            const products = _.filter(draft.products, (product) => {
                if (productType && product.type !== productType) {
                    return false
                }
                return true
            })
            quantityCount += _.sumBy(products, (product) => {
                return product.quantity
            })
        })
        return quantityCount
    },
    getProductQuantity: (state, getters) => (userId, residentId, productId) => {
        const drafts = getters.getDrafts(userId, residentId)
        if (!drafts || drafts.length < 1) {
            return 0
        }
        let quantity = 0
        drafts.forEach((draft) => {
            const productFound = _.find(draft.products, (product) => {
                return productId === product.id
            })
            if (productFound) {
                quantity += productFound.quantity
            }
        })
        return quantity
    },
    getProducts: (state, getters) => (userId, residentId, draftType, productType) =>  {
        let products = []
        const drafts = getters.getDrafts(userId, residentId, draftType)
        if (!drafts || drafts.length < 1) {
            return products
        }
        drafts.forEach((draft) => {
            const draftProducts = _.filter(draft.products, (product) => {
                return !(productType && product.type !== productType);
            })
            draftProducts.forEach((draftProduct) => {
                products.push(draftProduct)
            })
        })
        return products
    },
    getSubscriptionAmountByFrequency: (state, getters) => (userId, residentId) => {
        const drafts = getters.getDrafts(userId, residentId, 'subscription')
        return _(drafts)
            .flatMap(d => d.products)
            .groupBy(p => p.frequency)
            .mapValues(v => _.sumBy(v, p => p.price * p.quantity))
            .value()
    }
}
