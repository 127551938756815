import _ from 'lodash'
import {
    getDay,
    parse,
    parseISO,
    differenceInMinutes
} from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

import Http from '@/http'

function convertShopFromDto (shop) {
    return {
        id: shop.shopId,
        name: shop.shopName,
        contactInformation: {
            firstName: _.get(shop, 'contactInformations.firstname'),
            lastName: _.get(shop, 'contactInformations.lastname'),
            email: _.get(shop, 'contactInformations.email'),
            phone: _.get(shop, 'contactInformations.phone'),
            webSite: _.get(shop, 'contactInformations.webSite'),
            street1: _.get(shop, 'contactInformations.street1'),
            street2: _.get(shop, 'contactInformations.street2'),
            zipCode: _.get(shop, 'contactInformations.zipCode'),
            city: _.get(shop, 'contactInformations.city')
        },
    }
}
function convertOpeningHoursFromDto (openingHours) {
    return Object.entries(openingHours).flatMap(([day, ranges]) => {
        const weekday = getDay(parse(day, 'EEEE', new Date()))
        return ranges.map((range) => ({
            weekday,
            from: range.openingTime,
            to: range.closingTime
        }))
    })
}
function convertCustomerFromDto (customer) {
    return {
        id: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        civility: customer.civility,
        isInstitutionStaff: false
    }
}
function convertLocalDateAsUTC (date) {
    return zonedTimeToUtc(date, 'UTC')
}
function convertMessageFromDto (message) {
    return {
        id: message.id,
        content: message.content,
        createdAt: convertLocalDateAsUTC(message.createdOnUtc),
        createdBy: {
            id: _.get(message, 'createdBy.id'),
            firstName: _.get(message, 'createdBy.firstName'),
            lastName: _.get(message, 'createdBy.lastName')
        }
    }
}
function convertTimeSlotFromDto (timeSlot) {
    return {
        from: parseISO(timeSlot.from),
        to: parseISO(timeSlot.to)
    }
}
function findCustomAttribute (attributes, code) {
    return _.find(attributes, (attribute) => {
        return attribute.code == code
    })
}
function convertCalendarItemFromDto (sourceOrder) {
    const order = _.get(sourceOrder, 'order', {})
    const shop = _.get(order, 'shop', {})
    const consumption = _.get(order, 'orderContent.consumption', {})
    const customAttributeValues = _.get(order, 'orderContent.customAttributeValues', [])
    const bookingDuration = findCustomAttribute(customAttributeValues, 'booking-duration')
    const bookingDate = findCustomAttribute(customAttributeValues, 'booking-date')
    return {
        id: _.get(order, 'orderContent.id'),
        application: _.get(order, 'application'),
        commercialOrderId: _.get(order, 'orderId'),
        shop: convertShopFromDto(shop),
        openingHours: convertOpeningHoursFromDto(order.openingHours),
        customer: convertCustomerFromDto(sourceOrder.resident),
        orderLine: {
            id: _.get(order, 'orderContent.id'),
            logisticOrderId: _.get(order, 'orderContent.id'),
            serviceCode: _.get(order, 'orderContent.service.code'),
            title: _.get(order, 'orderContent.service.title'),
            imageUrl: _.get(order, 'orderContent.service.medias.[0].url'),
            totalAmount: _.get(order, 'orderContent.price.totalAmount'),
            options: _.get(order, 'orderContent.price.options', []),
            state: _.get(order, 'orderContent.state'),
            messages: _.get(order, 'orderContent.messages', []).map((message) =>
                convertMessageFromDto(message),
            ),
            booking: {
                timeSlots: _.get(order, 'orderContent.booking.timeSlots', []).map(
                    convertTimeSlotFromDto,
                ),
                additionalInfo: _.get(order, 'orderContent.booking.contactAdditionalInfo')
            },
            refunds: []
        },
        duration: _.get(bookingDuration, 'value'),
        booking: _.get(bookingDate, 'value') ? {
            date: parseISO(_.get(bookingDate, 'value')),
            bookedBy: order.bookedBy ? {
              id: _.get(order, 'bookedBy.id'),
              firstName: _.get(order, 'bookedBy.firstName'),
              lastName: _.get(order, 'bookedBy.lastName'),
            } : undefined,
        } : undefined,
        createdAt: _.get(order, 'orderContent.dateCreated'),
        consumedAt: consumption.dateConsumed ?
            parseISO(consumption.dateConsumed)
            : undefined,
        expirationDate: consumption.dateExpiration ?
            parseISO(consumption.dateExpiration)
            : undefined
    }
}

export default {
    fetchCalendarItems ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`order-logistic-services/to-place?UserId=${payload.userId}&InstitutionId=${payload.institutionId}&BookingDateFrom=${payload.bookingDateFrom}&BookingDateTo=${payload.bookingDateTo}`)
            .then((response) => {
                const orders = _.get(response, 'data.orders', [])
                const items = _.map(orders, (sourceOrder) => {
                    return convertCalendarItemFromDto(sourceOrder)
                })
                commit('SET_CALENDAR_ITEMS', items)
                resolve(items)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    validateBookingProposal ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            const calendarItem = _.get(payload, 'calendarItem', {})
            const bookingId = _.get(calendarItem, 'orderLine.logisticOrderId', 0)
            Http.apis.ehpad.put(`order-logistic-services/${bookingId}/booking`, {
                BookingDate: payload.startTime,
                BookingDuration: calendarItem.duration,
                BookedById: payload.userId
            })
            .then((response) => {
                const order = _.get(response, 'data.order')
                const finalCalendarItem = convertCalendarItemFromDto(order)
                commit('SET_CALENDAR_ITEM', {
                    calendarItem: finalCalendarItem
                })
                resolve(finalCalendarItem)
            })
        })
    },
    moveAppointment ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            const calendarItem = _.get(payload, 'calendarItem', {})
            const bookingId = _.get(calendarItem, 'orderLine.logisticOrderId', 0)
            const duration = differenceInMinutes(payload.endTime, payload.startTime)
            commit('SET_CALENDAR_ITEM', {
                calendarItem: {
                    ...calendarItem,
                    booking: {
                        ...calendarItem.booking,
                        date: payload.startTime,
                    },
                    duration
                }
            })
            Http.apis.ehpad.put(`order-logistic-services/${bookingId}/booking`, {
                BookingDate: payload.startTime,
                BookingDuration: duration,
                BookedById: payload.userId
            })
            .then((response) => {
                const order = _.get(response, 'data.order')
                const finalCalendarItem = convertCalendarItemFromDto(order)
                commit('SET_CALENDAR_ITEM', {
                    calendarItem: finalCalendarItem
                })
                resolve(finalCalendarItem)
            })
        })
    },
    fetchAcceptableCalendarItems ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/order-logistic-services/to-accept?userId=${payload.userId}&institutionId=${payload.institutionId}`)
            .then((response) => {
                const orders = _.get(response, 'data.orders', [])
                const items = _.map(orders, (sourceOrder) => {
                    return convertCalendarItemFromDto(sourceOrder)
                })
                commit('SET_ACCEPTABLE_CALENDAR_ITEMS', items)
                resolve(items)
            })
        })
    },
    acceptAppointment ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            const userId = _.get(payload, 'userId')
            const calendarItem = _.get(payload, 'calendarItem', {})
            const logisticOrderId = _.get(calendarItem, 'orderLine.logisticOrderId', {})
            // PUT /order-logistic-services/{id}/accept
            Http.apis.ehpad.put(`order-logistic-services/${logisticOrderId}/accept`, {
                userId
            })
            .then((response) => {
                const order = _.get(response, 'data.order')
                const finalCalendarItem = convertCalendarItemFromDto(order)
                commit('SET_ACCEPTABLE_CALENDAR_ITEM', {
                    calendarItem: finalCalendarItem
                })
                resolve(finalCalendarItem)
            })
        })
    },
    refuseAppointment ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            const userId = _.get(payload, 'userId')
            const calendarItem = _.get(payload, 'calendarItem', {})
            const logisticOrderId = _.get(calendarItem, 'orderLine.logisticOrderId', {})
            // PUT /order-logistic-services/{id}/refuse
            Http.apis.ehpad.put(`order-logistic-services/${logisticOrderId}/refuse`, {
                userId
            })
            .then((response) => {
                const order = _.get(response, 'data.order')
                const finalCalendarItem = convertCalendarItemFromDto(order)
                commit('SET_ACCEPTABLE_CALENDAR_ITEM', {
                    calendarItem: finalCalendarItem
                })
                resolve(finalCalendarItem)
            })
        })
    },
    fetchConsumableCalendarItems ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/order-logistic-services/to-consume?userId=${payload.userId}&institutionId=${payload.institutionId}`)
            .then((response) => {
                const orders = _.get(response, 'data.orders', [])
                const items = _.map(orders, (sourceOrder) => {
                    return convertCalendarItemFromDto(sourceOrder)
                })
                commit('SET_CONSUMABLE_CALENDAR_ITEMS', items)
                resolve(items)
            })
        })
    },
    consumeAppointment ({ commit }, payload ) {
        return new Promise((resolve, reject) => {
            const userId = _.get(payload, 'userId')
            const calendarItem = _.get(payload, 'calendarItem', {})
            const logisticOrderId = _.get(calendarItem, 'orderLine.logisticOrderId', {})
            // PUT /order-logistic-services/{id}/consume
            Http.apis.ehpad.put(`order-logistic-services/${logisticOrderId}/consume`, {
                userId
            })
            .then((response) => {
                const order = _.get(response, 'data.order')
                const finalCalendarItem = convertCalendarItemFromDto(order)
                commit('SET_CONSUMABLE_CALENDAR_ITEM', {
                    calendarItem: finalCalendarItem
                })
                resolve(finalCalendarItem)
            })
        })
    }
}
