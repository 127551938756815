import _ from 'lodash'
import Http from '@/http'

export default {
    getBasketById: state => (basketId) => {
        return _.find(state.alls, (basket) => {
            return basket.id == basketId
        })
    },
    getBasketIndexById: state => (basketId) => {
        return _.findIndex(state.alls, (basket) => {
            return basket.id == basketId
        })
    },
    getResidentBasket: (state) => (residentId) =>  {
        return _.find(state.alls, (basket) => {
            return basket.customerId == residentId
        })
    },
    getBasketCount: (state, getters) => (residentId) =>  {
        const basket = getters.getResidentBasket(residentId)
        if (!basket) {
            return 0
        }
        const productCount = _.sumBy(basket.basketLineProducts, (product) => {
            return product.quantity
        })
        const serviceCount = _.sumBy(basket.basketLineServices, (service) => {
            return service.quantity
        })
        return productCount + serviceCount
    },
    getBasketAmount: (state, getters) => (residentId) =>  {
        const basket = getters.getResidentBasket(residentId)
        if (!basket) {
            return 0.0
        }
        const productAmount = _.sumBy(basket.basketLineProducts, (product) => {
            return product.quantity * _.get(product, 'unitPrice', 0)
        })
        const serviceAmount = _.sumBy(basket.basketLineServices, (service) => {
            return service.quantity * _.get(service, 'unitPrice', 0)
        })
        return (productAmount + serviceAmount).toFixed(2)
    }
}
