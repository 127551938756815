import _ from 'lodash'

export default {
    SET_TUTORS (state, tutors) {
        state.tutors = tutors
    },
    ADD_TUTOR (state, tutor) {
        state.tutors.push(tutor)
    },
    SET_TUTORS_IS_REGISTERED (state, isRegistered) {
        //isRegistered: boolean
        state.isRegistered = isRegistered
    }
}
