import _ from 'lodash'

export default {
    SET_FORM (state, payload) {
        state.forms[payload.formId] = _.cloneDeep(payload.data)
    },
    DELETE_FORM (state, payload) {
        delete state.forms[payload.formId]
    }
}
