export default {
    DisplayDate: 'DD/MM/YYYY',
    SystemDate: 'YYYY-MM-DD',
    SystemDateDefault: '',
    InvoiceDate: 'DD-MM-YYYY',
    SimpleDate: 'DD-MM-YYYY',
    SlashDate: 'DD/MM/YYYY',
    PcpDate: 'DD/MM/YYYY HH:mm',
    LogisticDate: 'DD/MM/YYYY - HH:mm',
    RequestDate: 'YYYY-MM-DD HH:mm:ss',
    OrderDate: 'DD/MM/YYYY à HH:mm',
    DisplayDateText: 'DD MMMM'
}
