import { ServiceState } from './service-state'
import { ServicePublicData } from '@/types/api-missing'
import { ServiceDto } from '@happytal/ecommerce-api'
import {
    ServiceAsShop,
    ServiceOption,
    SelectedBooleanOptionValue,
    SelectedValueListOptionValue
} from '@/types/happydom-store'

export default {
    checkIfMandatoryOptionsAreSelected: (state: ServiceState, getters: any) => {
        const booleanOptions: ServiceOption[] = state.serviceOptions['BOOLEAN']
        const publicData = (state.serviceModel?.publicData as unknown) as ServicePublicData
        const mandatoryValueListOptionsCodes: string[] = publicData?.mandatoryOptions || []
        let  [checkBooleanOptions, checkMandatoryValueListOptions] = [true, true]

        if (booleanOptions.length) {
            checkBooleanOptions = booleanOptions.every(
                opt => Boolean(state.selectedBooleanOptionValues.find( (el: SelectedBooleanOptionValue) => el.optionCode === opt.code))
            )
        }

        if (mandatoryValueListOptionsCodes.length) {
            checkMandatoryValueListOptions = mandatoryValueListOptionsCodes.every(
                optionCode => {
                    const selectedOption = state.selectedValueListOptionValues.find(
                        (el: SelectedValueListOptionValue) => el.optionCode === optionCode
                    )
                    if (!selectedOption) return false
                    return Boolean(selectedOption.values.length)
                }
            )
        }

        if (!checkBooleanOptions || !checkMandatoryValueListOptions) {
            return false
        }

        return true
    },

    availableServicesForSelection: (state: ServiceState) => {
        return state.services.filter(service => {

            const publicData = (state.serviceModel?.publicData as unknown) as ServicePublicData
            const checkMandatoryOptions = publicData?.mandatoryOptions?.every(
                code => Boolean( service?.pricing?.options?.find(o => o.code === code) )
            ) ?? true

            //If no option has been selected yet, just check if the service offers all the mandatory options
            if (!state.selectedBooleanOptionValues.length && !state.selectedValueListOptionValues.length) return checkMandatoryOptions

            const checkBooleanOptionsSelected = state.selectedBooleanOptionValues.every(selectedOption => {
                const serviceOptionToCheck =  service.pricing?.options?.find(o => o.code === selectedOption.optionCode)

                const isValueFound = serviceOptionToCheck?.values?.findIndex(serviceValue => serviceValue.value === selectedOption?.value?.value)

                return isValueFound !== -1
            })

            const checkValueListOptionsSelected = state.selectedValueListOptionValues.every(selectedOption => {
                const serviceOptionToCheck =  service.pricing?.options?.find(o => o.code === selectedOption.optionCode)

                if (!serviceOptionToCheck) return false

                return selectedOption?.values?.every(
                     selectedValue => {
                         return serviceOptionToCheck?.values?.findIndex( serviceValue => serviceValue.code === selectedValue.code) !== -1
                    }
                )
            })

            return checkMandatoryOptions && checkBooleanOptionsSelected && checkValueListOptionsSelected
        })
    },

    availableServicesAsShops: (state: ServiceState, getters: any) => {
        let services: ServiceAsShop[] = []

        if (!state.selectedBooleanOptionValues.length && !state.selectedValueListOptionValues.length) {
            services = getters.availableServicesForSelection.map( (service: ServiceDto) => ({
                ...service,
                totalAmount: getters.checkIfMandatoryOptionsAreSelected ? service?.pricing?.pricePerUnit ?? 0 : 0
            }))
        } else {
            services = getters.availableServicesForSelection.map( (service: ServiceDto) => {
                let totalAmount = 0

                state.selectedBooleanOptionValues.forEach(selectedOption => {
                    const serviceOption = service.pricing?.options?.find(opt => opt.code === selectedOption.optionCode)
                    const serviceOptionValue = serviceOption?.values?.find(val => val.value === selectedOption.value?.value)

                    totalAmount += serviceOptionValue?.amount ?? 0
                })

                state.selectedValueListOptionValues.forEach(selectedOption => {
                    const serviceOption = service.pricing?.options?.find(opt => opt.code === selectedOption.optionCode)

                    selectedOption?.values?.forEach(selectedValue => {
                        const serviceOptionValue = serviceOption?.values?.find(val => val.code === selectedValue?.code)

                        totalAmount += serviceOptionValue?.amount ?? 0
                    })

                })

                if (getters.checkIfMandatoryOptionsAreSelected && service?.pricing?.pricePerUnit) {

                    totalAmount = totalAmount + service.pricing.pricePerUnit
                }


                return {...service, totalAmount}
            })
        }

        return services.filter(s => s.pricing)
    },

    cheapeastServiceAvailable: (state: ServiceState, getters: any) => {
        if ( getters.availableServicesAsShops.length === 0 ) return null
        if ( getters.availableServicesAsShops.length === 1 ) return getters.availableServicesAsShops[0]

        return getters.availableServicesAsShops.reduce( (s1: ServiceAsShop, s2: ServiceAsShop) => {
            if (s1.totalAmount === 0 ) return s2
            if (s2.totalAmount === 0 ) return s1
            if (s1.totalAmount < s2.totalAmount) return s1
            else return s2
        })
    },

    serviceSelected: (state: ServiceState, getters: any) => {
        if (state.isUserHasManifestedAChoice) {
            return getters.availableServicesAsShops.find( (service: ServiceAsShop) => service.code === state.serviceSelectedCode)
        }

        return getters.cheapeastServiceAvailable
    },

    getValueOptionInServiceSelected: (_state: any, getters: any) => (optionCode: string, valueKey: string) => {
        const option = getters.serviceSelected?.pricing?.options?.find( (o:any) => o.code === optionCode)
        const value = option?.values?.find((v:any) => {
            if (option.type === 'BOOLEAN') return v.value === valueKey
            return v.code === valueKey
        })

        return value
    },

    optionsValuesSelected: (state: ServiceState, getters: any) => {
        const optionsValuesSelected: any[] = []

        state.selectedBooleanOptionValues.forEach( ({ optionCode, optionLabel, value}) => {
            if (value === null) return
            const serviceValue = getters.getValueOptionInServiceSelected(optionCode, value.value)

            if ( serviceValue && (serviceValue.value || (!serviceValue.value && serviceValue.amount !== 0)) ) {
                optionsValuesSelected.push(
                    {
                        ...serviceValue,
                        label: value.value ? optionLabel : `${optionLabel} (non)`
                    }
                )
            }
        })

        state.selectedValueListOptionValues.forEach( ({ optionCode, values}) => {
            values.forEach(selectedValue => {
                const value = getters.getValueOptionInServiceSelected(optionCode, selectedValue.code)
                if (value) optionsValuesSelected.push(value)
            })
        })

        return optionsValuesSelected
    },

    isValueOptionDisabled: (state: ServiceState, getters: any) => (optionCode: string, valueKey: string) => {
        if (state.selectedValueListOptionValues.length && state.selectedBooleanOptionValues.length === 0) return false

        if (state.isUserHasManifestedAChoice) return !getters.getValueOptionInServiceSelected(optionCode, valueKey)

        const optionStoreInSelection = state.selectedValueListOptionValues.find(opt => opt.optionCode === optionCode)

        if (!optionStoreInSelection) {
            return false
        } else {
            const codesValuesToCheck = [
                ...optionStoreInSelection.values?.map(v => v.code)?.filter(code => code !== valueKey) as string[],
                valueKey
            ]

            const serviceThatOwnsValues = getters.availableServicesForSelection.find(
                (service: ServiceDto) => {
                    const optionToCheck = service?.pricing?.options?.find( opt => opt.code === optionCode)
                    if (!optionToCheck) return false
                    return codesValuesToCheck.every( code => Boolean(optionToCheck.values?.find( v => v.code === code) ) )
                }
            )

            return !serviceThatOwnsValues
        }
    },
}
