import { Commit } from 'vuex/types'
import { FrequencySelectOption } from '@/types/components'

interface BuyBoxState {
    isSuggestionMode: boolean,
    suggestionEmitterName: string
    subscriptionMode: boolean,
    subscriptionFrequency: FrequencySelectOption | null,
    isCurrentSubscription: boolean,
    quantity: number,
    amount: number,
    isVisibleAddBtn: boolean
}

const baseState = {
    isSuggestionMode: false,
    suggestionEmitterName: '',
    subscriptionMode: false,
    subscriptionFrequency: null,
    isCurrentSubscription: false,
    quantity: 1,
    amount: 0,
    isVisibleAddBtn: false
} as BuyBoxState

export default {
    isRegistered: false,

    namespaced: true,

    state: { ...baseState } as BuyBoxState,

    getters: {
        getTotalAmount: (state: BuyBoxState) => Number( (state.amount * state.quantity).toFixed(2) ),
    },

    mutations: {
        RESET_STATE(state: BuyBoxState) {
            Object.keys(baseState).forEach(property => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                state[property] = baseState[property]
            })
        },

        UPDATE_IS_SUGGESTION_MODE(state: BuyBoxState, value: boolean) {
            state.isSuggestionMode = value
        },

        UPDATE_SUGGESTION_EMITTER_NAME(state: BuyBoxState, name: string) {
            state.suggestionEmitterName = name
        },

        UPDATE_SUBSCRIPTION_MODE(state: BuyBoxState, value: boolean) {
            state.subscriptionMode = value
        },

        UPDATE_SUBSCRIPTION_FREQUENCY(state: BuyBoxState, frequency: FrequencySelectOption | null) {
            state.subscriptionFrequency = frequency
        },

        UPDATE_IS_CURRENT_SUBSCRIPTION(state: BuyBoxState, value: boolean) {
            state.isCurrentSubscription = value
        },

        UPDATE_QUANTITY(state: BuyBoxState, quantity: number) {
            state.quantity = quantity
        },

        UPDATE_AMOUNT(state: BuyBoxState, amount: number) {
            state.amount = amount
        },

        UPDATE_IS_VISIBLE_ADD_BTN(state: BuyBoxState, value: boolean) {
            state.isVisibleAddBtn = value
        },
    },
    
    actions: {
        resetState( { commit }: { commit: Commit } ) {
            commit('RESET_STATE')
        },

        updateIsSuggestionMode( { commit }: { commit: Commit }, value: boolean) {
            commit('UPDATE_IS_SUGGESTION_MODE', value)
        },

        updateSuggestionEmitterName( { commit }: { commit: Commit }, name: string) {
            commit('UPDATE_SUGGESTION_EMITTER_NAME', name)
        },

        updateSubscriptionMode( { commit }: { commit: Commit }, value: boolean) {
            commit('UPDATE_SUBSCRIPTION_MODE', value)
        },

        updateSubscriptionFrequency( { commit }: { commit: Commit }, frequency: FrequencySelectOption | null) {
            commit('UPDATE_SUBSCRIPTION_FREQUENCY', frequency)
        },

        updateIsCurrentSubscription( { commit }: { commit: Commit }, value: boolean) {
            commit('UPDATE_IS_CURRENT_SUBSCRIPTION', value)
        },

        updateQuantity( { commit }: { commit: Commit }, quantity: number) {
            commit('UPDATE_QUANTITY', quantity)
        },

        updateAmount( { commit }: { commit: Commit }, amount: number) {
            commit('UPDATE_AMOUNT', amount)
        },

        updateIsVisibleAddBtn( { commit }: { commit: Commit }, value: boolean) {
            commit('UPDATE_IS_VISIBLE_ADD_BTN', value)
        },
    }
}