import _ from 'lodash'

export default {
    getUserId: (state) => () => {
        return _.get(state, 'user.id', null)
    },
    getInstitutionId: (state) => () => {
        return _.get(state, 'user.selectedInstitutionId', null)
    },
    getUserInstitutionIds: (state) => () => {
        return _.get(state, 'user.institutionIds', [])
    },
    getUserToken: (state) => () => {
        return _.get(state, 'user.auth.access_token', null)
    },
    getUserDisplayName: (state) => {
        return _.get(state, 'user.displayName', null)
    },
    getUserName: (state) => (i18nGenders) => {
        const gender = _.get(state, 'user.gender', null)
        const last_name = _.get(state, 'user.last_name', null)
        return `${i18nGenders[gender]} ${last_name}`
    },
    getUserRole: (state) => (i18nRoles) => {
        const role = _.get(state, 'user.role', null)
        return i18nRoles[role]
    },
    getAcceptCommunications: (state) => () => {
        return _.get(state, 'user.acceptCommunications', null)
    },
    getSuggestionsNotificationsEnabled: (state) => () => {
        return _.get(state, 'user.suggestionsNotificationsEnabled', false)
    },
}
