import {
    ServiceDto,
    IFrontCategoryDto,
    ServiceModelDto
} from '@happytal/ecommerce-api'
import {
    ServiceOptions,
    SelectedBooleanOptionValue,
    SelectedValueListOptionValue
} from '@/types/happydom-store'

export interface ServiceState {
    category: IFrontCategoryDto | undefined
    services: ServiceDto[]
    serviceModel: ServiceModelDto | undefined
    serviceSelectedCode: string
    serviceOptions: ServiceOptions,
    selectedBooleanOptionValues: SelectedBooleanOptionValue[]
    selectedValueListOptionValues: SelectedValueListOptionValue[],
    isUserHasManifestedAChoice: boolean
}

export default {
    category: undefined,
    services: [],
    serviceModel: undefined,
    serviceSelectedCode: '',
    serviceOptions: {
        "BOOLEAN": [],
        "VALUE_LIST": [],
    },
    selectedBooleanOptionValues: [],
    selectedValueListOptionValues: [],
    isUserHasManifestedAChoice: false
}
