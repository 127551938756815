import _ from 'lodash'

export default {
    getInstitutionById: state => (institutionId) => {
        return _.find(state.institutions, (institution) => {
            return institution.id == institutionId
        })
    },
    getInstitutionName: (state) => (id) => {
        var institutions = _.get(state, 'institutions', [])
        const result = _.find(institutions, (institution) => {
            return institution.id == id
        })
        return result.name
    }
}
