import state from './service-state'
import mutations from './service-mutations'
import actions from './service-actions'
import getters from './service-getters'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
