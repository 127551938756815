import Http from "@/http"
import Routes from '@/constants/routes'
import { Location } from 'vue-router'

import { Commit } from 'vuex/types'
import { IItemDto, IPaginationDto } from '@happytal/ecommerce-api'
import { ProductItem } from '@/types/components'

interface CatalogProductsState {
    categoryProducts: IItemDto[]
    categoryProductsLoaded: boolean
    categoryProductsPagination: IPaginationDto

    searchProducts: IItemDto[]
    searchProductsLoaded: boolean
    searchProductsPagination: IPaginationDto
}
const defaultPagination = {
    pageIndex: 1,
    totalPages: 1,
    totalCount: 0,
    hasPreviousPage: false,
    hasNextPage: false
}
function mapProductsForFront(products: IItemDto[]): ProductItem[] {
    return products.map(product => {
        const { title = '', imageUrl = '', brand = '', type = '', quantity = ''} = product
        let id = product.id ?? ''
        let price = product.minPrice ?? product.offers?.[0]?.price ?? -1
        let route: Location = {
            name: Routes.CatalogProduct,
            params: {
                product_id: id
            }
        }

        if (product.variantCode) {
            const cheapestVariantProduct = product.offers?.reduce(
                (acc, curr) => {
                    const accPrice = acc?.price || 0
                    const currPrice = curr?.price || 0
                    if (accPrice <= currPrice) return acc
                    else return curr
                }
            )

            if (cheapestVariantProduct) {
                /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
                id = cheapestVariantProduct.productSku!
                price = cheapestVariantProduct.price || 0
            }

            route = {
                name: Routes.CatalogVariant,
                params: {
                    product_id: id,
                    variant_id: product.variantCode
                }
            }

        }
        else if (product.type === 'Service') {
            route = {
                name: Routes.CatalogService,
                params: {
                    service_model_id: id
                }
            }
        }

        return { id, title, imageUrl, brand,  price, route, type, quantity }
    })
}

export default {
    isRegistered: false,

    namespaced: true,

    state: {
        categoryProducts: [],
        categoryProductsLoaded: false,
        categoryProductsPagination: Object.assign({}, defaultPagination),

        searchProducts: [],
        searchProductsLoaded: false,
        searchProductsPagination: Object.assign({}, defaultPagination),
    } as CatalogProductsState,

    getters: {
        categoryProductsForFront: (state: CatalogProductsState): ProductItem[] => {
            return mapProductsForFront(state.categoryProducts)
        },
        searchProductsForFront: (state: CatalogProductsState): ProductItem[] => {
            return mapProductsForFront(state.searchProducts)
        },
    },

    mutations: {
        SET_CATEGORY_PRODUCTS(
            state: CatalogProductsState,
            { items, pagination } : { items: IItemDto[], pagination:  IPaginationDto }
        ) {
            state.categoryProducts = items
            state.categoryProductsPagination = pagination
        },
        SET_CATEGORY_PRODUCTS_LOADED(state: CatalogProductsState, value: boolean) {
            state.categoryProductsLoaded = value
        },
        RESET_CATEGORY_PRODUCTS(state: CatalogProductsState) {
            state.categoryProducts = []
            state.categoryProductsLoaded = false
            state.categoryProductsPagination = Object.assign({}, defaultPagination)
        },


        SET_SEARCH_PRODUCTS(
            state: CatalogProductsState,
            { items, pagination } : { items: IItemDto[], pagination:  IPaginationDto }
        ) {
            state.searchProducts = items
            state.searchProductsPagination = pagination
        },
        SET_SEARCH_PRODUCTS_LOADED(state: CatalogProductsState, value: boolean) {
            state.searchProductsLoaded = value
        },
        RESET_SEARCH_PRODUCTS(state: CatalogProductsState) {
            state.searchProducts = []
            state.searchProductsLoaded = false
            state.searchProductsPagination = Object.assign({}, defaultPagination)
        },
    },

    actions: {
        async getCategoryProducts(
            { commit }: { commit: Commit },
            { institutionId, categoryId, pageIndex, pageSize } : Record<string, string | number>
        ) {
            try {
                const productsResponse = await Http.apis.ehpad.get(`/items?application=happydom&frontCategoryIds=${categoryId}&institutionId=${institutionId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)

                const { items, pagination } = productsResponse.data ?? { items: [], pagination: null }

                commit('SET_CATEGORY_PRODUCTS', { items, pagination })
                commit('SET_CATEGORY_PRODUCTS_LOADED', true)

            } catch (error: any) {
                commit('SET_CATEGORY_PRODUCTS_LOADED', true)
                return new Error(`Une erreur s'est produite pendant le chargement des produits de la catégorie.`)
            }
        },
        resetCategoryProducts( { commit }: { commit: Commit } ) {
            commit('RESET_CATEGORY_PRODUCTS')
        },

        async getSearchProducts(
            { commit }: { commit: Commit },
            { searchText, institutionId, pageIndex, pageSize } : Record<string, string | number>
        ) {
            try {
                const productsResponse = await Http.apis.ehpad.get(`/items?application=happydom&search=${searchText}&institutionId=${institutionId}&pageIndex=${pageIndex}&pageSize=${pageSize}`)

                const { items, pagination } = productsResponse.data ?? { items: [], pagination: null }

                commit('SET_SEARCH_PRODUCTS', { items, pagination })
                commit('SET_SEARCH_PRODUCTS_LOADED', true)

            } catch (error: any) {
                commit('SET_SEARCH_PRODUCTS_LOADED', true)
                return new Error(`Une erreur s'est produite pendant le chargement des produits de la catégorie.`)
            }
        },
        resetSearchProducts( { commit }: { commit: Commit } ) {
            commit('RESET_SEARCH_PRODUCTS')
        },
    }
}
