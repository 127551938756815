import state from './products-state.js'
import mutations from './products-mutations.js'
import actions from './products-actions.js'
import getters from './products-getters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
