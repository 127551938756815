export default {
    Home: 'home',
    Dashboard: 'dashboard',
    Residents: 'residents',
    HelpCategory: 'rubrique-aidant',
    HelpCategoryDetail: 'rubrique-aidant-detail',
    ResidentCatalog: 'resident-catalog',
    CatalogExplorer: 'catalog-explorer',
    CatalogProduct: 'catalog-product',
    CatalogVariant: 'catalog-variant',
    CatalogService: 'catalog-service',
    ResidentInformations: 'resident-informations',
    ResidentDashboard: 'resident-dashboard',
    ResidentProfile: 'resident-profile',
    ResidentSubscriptionsCurrent: 'resident-subscriptions-current',
    ResidentSubscriptionsNext: 'resident-subscriptions-next',
    ResidentDemands: 'resident-demands',
    ResidentPayment: 'resident-payment',
    ResidentHistory: 'resident-history',
    ResidentOrder: 'resident-order',
    ResidentSummary: 'resident-summary',
    ResidentSuggestions: 'resident-suggestions',
    ResidentSuggestionsPending: 'resident-suggestions-pending',
    ResidentSuggestionsToProcess: 'resident-suggestions-to-process',
    ResidentSuggestionsHistory: 'resident-suggestions-history',
    ResidentHelpCategory: 'resident-rubrique-aidant',
    ResidentHelpCategoryDetail: 'resident-rubrique-aidant-detail',
    Orders: 'orders',
    NewResident: 'new-resident',
    UpdateResident: 'update-resident',
    NewReferant: 'new-referant',
    HelpCenter: 'help-center',
    ResidentStoreBasket: 'resident-store-basket',
    ResidentStoreShipping: 'resident-store-shipping',
    ResidentStorePayment: 'resident-store-payment',
    ResidentStoreThankYou: 'resident-store-thank-you',
    UserAccountExpired: 'user-account-expired',
    ForgotPasswordExpired: 'forgot-password-expired',
    LogisticShipping: 'logistic-shipping',
    LogisticAppointments: 'logistic-appointments',
    UserProfile: 'user-profile',
    ExtraServices: 'extra-services',
    ExtraService: 'extra-service',
    SuggestionDashboard: 'suggestion-dashboard',
    AlgoliaProductSearch: 'algolia-product-search'
}
