import { flatMap, sum } from 'lodash'

function computeShoppingListServiceTotalAmount(shoppingListService, services) {
    const model = services.find(s => s.services.find(s => s.code === shoppingListService.serviceCode))
    if (!model) {
        console.debug(`Service ${shoppingListService.serviceCode} not found in model`)
        return NaN
    }
    const service = model.services.find(s => s.code === shoppingListService.serviceCode)
    const optionAmounts = shoppingListService.options.map(shoppingListServiceOption => {
        const serviceOption = service.pricing.options.find(o => o.code === shoppingListServiceOption.code)
        if (!serviceOption) {
            return Nan
        }
        switch (shoppingListServiceOption.type) {
            case 'BOOLEAN':
                const value = serviceOption.values.find(v => v.value === shoppingListServiceOption.value)
                if (!value) {
                    console.debug(`Value ${shoppingListServiceOption.value} not found in option ${serviceOption.code}`)
                    return [NaN]
                }
                return [value.amount]
            case 'VALUE_LIST':
                return shoppingListServiceOption.values.map(shoppingListServiceOptionValue => {
                    const value = serviceOption.values.find(v => v.code === shoppingListServiceOptionValue.code)
                    if (!value) {
                        console.debug(`Value ${shoppingListServiceOptionValue.code} not found in option ${serviceOption.code}`)
                        return NaN
                    }
                    return value.amount
                })
            default:
                console.error(`Option type not handled in price: ${shoppingListServiceOption.type}`)
        }
    })
    return service.pricing.pricePerUnit + sum(flatMap(optionAmounts))
}

function computeShoppingListServiceTotalAmount2(shoppingListService) {
    const service = shoppingListService.meta.service
    const optionAmounts = shoppingListService.options.map(shoppingListServiceOption => {
        const serviceOption = service.pricing.options.find(o => o.code === shoppingListServiceOption.code)
        if (!serviceOption) {
            return Nan
        }
        switch (shoppingListServiceOption.type) {
            case 'BOOLEAN':
                const value = serviceOption.values.find(v => v.value === shoppingListServiceOption.value)
                if (!value) {
                    console.debug(`Value ${shoppingListServiceOption.value} not found in option ${serviceOption.code}`)
                    return [NaN]
                }
                return [value.amount]
            case 'VALUE_LIST':
                return shoppingListServiceOption.values.map(shoppingListServiceOptionValue => {
                    const value = serviceOption.values.find(v => v.code === shoppingListServiceOptionValue.code)
                    if (!value) {
                        console.debug(`Value ${shoppingListServiceOptionValue.code} not found in option ${serviceOption.code}`)
                        return NaN
                    }
                    return value.amount
                })
            default:
                console.error(`Option type not handled in price: ${shoppingListServiceOption.type}`)
        }
    })
    return service.pricing.pricePerUnit + sum(flatMap(optionAmounts))
}

/*function getShoppingListServiceDescription(shoppingListService) {
    let description = ''
    for (const option of shoppingListService.options) {
        switch (option.type) {
            case 'BOOLEAN':
                if (option.value) {
                    description += option.label + '<br>'
                }
                break;
            case 'VALUE_LIST':
                for (const value of option.values) {
                    description += value.label + '<br>'
                }
                break
            default:
                console.error(`Option type not handled: ${option.type}`)
        }
    }
    return description
}*/

function getDescriptionAsHtml(description, options) {
    let result = ''
    if (description) {
        result += `${description}<br />`
    }
    if (options) {
        result += options
    }
    return result
}

const optionHeaderHtml = `<strong>Options :</strong><br />`

function getOrderServiceOptionsAsHtml(options) {
    if (options.length < 1) {
        return null
    }
    var legend = optionHeaderHtml
    options.forEach((option) => {
        switch (option.type) {
            case 'BOOLEAN':
                legend += `- ${option.label} : ${option.value ? 'Oui' : 'Non'}<br />`
                break
            case 'VALUE_LIST':
                for (const value of option.values) {
                    legend += `- ${value.label}<br />`
                }
                break
        }
    })
    return legend
}

function getServiceOptionsAsHtml(service) {
    var legend = optionHeaderHtml
    const options = _.get(service, 'meta.options', [])
    if (options.length < 1) {
        return null
    }
    options.forEach((option) => {
        const serviceModel = service.meta.serviceModel
        const serviceModelOption = serviceModel.pricing.options.find(serviceModelOption => serviceModelOption.code === option.code)
        switch (option.type) {
            case 'BOOLEAN':
                legend += `- ${serviceModelOption.label} : ${option.value ? 'Oui' : 'Non'}<br />`
                break
            case 'VALUE_LIST':
                for (const value of option.values) {
                    const serviceModelValue = serviceModelOption.availableValues.find(serviceModelValue => serviceModelValue.code === value.code)
                    legend += `- ${serviceModelValue.label}<br />`
                }
                break
        }
    })
    return legend
}

function getProductOptionsAsHtml(product) {
    let legend = optionHeaderHtml
    const variantData = _.get(product, 'variantInfo.data', [])
    const productData = _.get(product, 'product.data', {})
    const productSku = _.get(product, 'product.productSku', null)
    let foundVariant = false
    for (const variant of variantData) {
        if (variant.productSkus.includes(productSku)) {
            const variantKey = _.get(productData, `[${variant.code}]`, null)
            const variantValue = _.get(variant, `valuesData[${variantKey}]`, null)
            legend += ` - ${variant.label} : ${variantValue}<br />`
            foundVariant = true
        }
    }
    return foundVariant ? legend : ''
}

function computeBasketTotal(basket) {
    let total = 0
    if (basket && basket && basket.quoteContent && basket.quoteContent.quoteContentProduct) {
        basket.quoteContent.quoteContentProduct.orders.forEach((item) => {
            item.offers.forEach((order) => {
                total +=  order.linePrice
            })
        })
    }
    if (basket && basket && basket.quoteContent) {
        total += basket.quoteContent.totalAmountService
    }
    return total
}

function getUnitShippingPrice(listTypeShipping, selectedShipping) {
    let mode = listTypeShipping.find((shipping) => {
        return shipping.code === selectedShipping.code
    })
    if (!mode) {
        return 0
    }
    return mode.totalShippingPrice
}

function computeBasketShipping(basket) {
    let total = 0
    if (basket && basket && basket.quoteContent && basket.quoteContent.quoteContentProduct) {
        basket.quoteContent.quoteContentProduct.orders.forEach((item) => {
            total += getUnitShippingPrice(item.shippingTypes, item.selectedShippingType)
        })
    }
    return total
}

export {
    computeShoppingListServiceTotalAmount,
    computeShoppingListServiceTotalAmount2,
    getDescriptionAsHtml,
    getProductOptionsAsHtml,
    getOrderServiceOptionsAsHtml,
    getServiceOptionsAsHtml,
    computeBasketTotal,
    computeBasketShipping
}
