import _ from 'lodash'

/* One of rules matches */
function some (acl, rules) {
    return _.some(rules, (rule) => {
        return acl.check(rule)
    })
}

/* One of rules dont matches */
function notSome (acl, rules) {
    return _.some(rules, (rule) => {
        return !acl.check(rule)
    })
}

/* All rules matches */
function every (acl, rules) {
    return _.every(rules, (rule) => {
        return acl.check(rule)
    })
}

/* All rules dont matches */
function notEvery (acl, rules) {
    return _.every(rules, (rule) => {
        return !acl.check(rule)
    })
}

export default {
    some,
    notSome,
    every,
    notEvery
}
