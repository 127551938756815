import state from './tutors-state.js'
import mutations from './tutors-mutations.js'
import actions from './tutors-actions.js'
import getters from './tutors-getters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
