import {
    uuidv4
} from '@/modules/security'

export default {
    create ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const draftId = uuidv4()
            const userId = payload.userId
            const residentId = payload.residentId
            const type = payload.type

            commit('CREATE_DRAFT', {
                draftId,
                userId,
                residentId,
                type,
                callback: resolve
            })
        })
    },

    async add({ getters, commit, dispatch }, { userId, residentId, type, draftProduct }) {
        let draft = getters.getFirstDraft(userId, residentId, type)
        if (!draft) {
            draft = await dispatch('create', {
                userId,
                residentId,
                type
            })
        }
        commit('ADD_OR_UPDATE_PRODUCT', {
            draftId: draft.id,
            product: draftProduct,
            increaseQuantity: true
        })
        commit('SET_POPUP_VISIBLE', {
            visible: true,
            product: draftProduct
        })
        setTimeout(() => {
                commit('SET_POPUP_VISIBLE', {
                    visible: false
                })
            },
            4000
        )
        commit('SAVE_DRAFTS')
    }
}
