import _ from 'lodash'
import axios from 'axios'
import decode from 'jwt-decode'
import randomBytes from 'randombytes'

import Storage from '@/storage'

import { sha256 } from '@/modules/security'
import { Base64 } from 'js-base64'
import { base64URLEncode } from '@/modules/strings'
import { urlGetParameter } from '@/modules/url'
import appsettings from '@/appsettings'

const clientId = appsettings.VUE_APP_CLIENT_ID
const vueAppRedirect = appsettings.VUE_APP_REDIRECT
const vueAppRedirectLogout = appsettings.VUE_APP_REDIRECT_LOGOUT
const vueAppRedirectGlobal = appsettings.VUE_APP_REDIRECT_GLOBAL
const vueUserApi = appsettings.VUE_APP_USER_API
const clientDomain = appsettings.VUE_APP_CLIENT_DOMAIN
const tokenAudience = appsettings.VUE_APP_TOKEN_AUDIENCE

class Oauth {

    encodeState(state) {
        const buffer = Base64.encode(JSON.stringify(state))
        return buffer.replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
    }
    decodeState(state) {
        const buffer = state.replace(/-/g, "\\")
        .replace(/_/g, '/')
        return JSON.parse(Base64.decode(buffer))
    }

    getLoginUrl(redirectUrl) {
        return new Promise((resolve, reject) => {
            const verifier = base64URLEncode(randomBytes(32))
            Storage.setVerifier(verifier)
            const challengeCode = base64URLEncode(sha256(verifier))
            var redirectUriSpecific = vueAppRedirect
            const redirect_uri = urlGetParameter('redirect_uri')
            if (redirect_uri && redirect_uri.length > 0) {
                redirectUriSpecific += `?redirect_uri=${redirect_uri}`
            }
            var authUrl = `${clientDomain}/authorize?scope=openid%20profile%20email%20happydom-user-data%20happydom-api-gateway&response_type=code&client_id=${clientId}&code_challenge=${challengeCode}&code_challenge_method=S256&redirect_uri=${redirectUriSpecific}&acr_values=tenant:EhpadSaas`
            if (redirectUrl) {
                const state = this.encodeState({
                    redirectUrl
                })
                authUrl += `&state=${state}`
            }
            resolve(authUrl)
        })
    }

    getAccessToken(code) {
        return new Promise((resolve, reject) => {
            const code_verifier = Storage.getVerifier()
            if (!code_verifier) {
                reject({
                    humanError: `Unable to retrieve code_verifier in browser localStorage`
                })
                return
            }
            const bodyFormData = new FormData()
            bodyFormData.set('grant_type', 'authorization_code')
            const redirect_uri = urlGetParameter('redirect_uri')
            var redirectUrl = vueAppRedirect
            if (redirect_uri && redirect_uri.length > 0) {
                redirectUrl += `?redirect_uri=${redirect_uri}`
            }
            bodyFormData.set('redirect_uri', redirectUrl)
            bodyFormData.set('client_id', clientId)
            bodyFormData.set('code', code)
            bodyFormData.set('code_verifier', code_verifier)
            bodyFormData.set('code_challenge_method', 'S256')
            axios({
                method: 'post',
                url: clientDomain + '/token',
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data'}
            })
            .then(response => {
                resolve(response.data)
            })
            .catch((error) => {
                error.humanError = `Unable to generate user access token from code (${code})`
                reject(error)
            })
        })
    }

    getUserInfos(user) {
        return new Promise((resolve, reject) => {
            const redirect_uri = urlGetParameter('redirect_uri')
            const token = _.get(user, 'auth.access_token')
            axios({
                method: 'get',
                url: `${vueUserApi}/${user.id}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                resolve(response.data.user)
            })
            .catch((error) => {
                error.humanError = `Unable to retrieve user informations for (${user.id})`
                reject(error)
            })
        })
    }

    login() {
        return new Promise((resolve, reject) => {
            const code = urlGetParameter('code')
            if (!code || code.length < 1) {
                reject({
                    humanError: `Oauth code url parameter not found`
                })
                return
            }
            var state = urlGetParameter('state')
            if (state) {
                state = this.decodeState(state)
            }

            // Get access token from auth API
            this.getAccessToken(code)
            .then(tokenData => {
                var user = {}
                user.auth = tokenData
                var accessTokenData = decode(tokenData.access_token)
                user.auth = Object.assign(user.auth, _.omit(accessTokenData, [
                    'ehs:id',
                    'ehs:institutionId',
                    'ehs:type',
                    'email'
                ]))
                user.id = accessTokenData['ehs:id']
                user.email = accessTokenData['email']
                user.role = accessTokenData['ehs:type']
                user.displayRole = user.role
                user.locale = 'fr'

                // Get user infos from user API gateway
                this.getUserInfos(user)
                .then((userData) => {

                    user = Object.assign(user, _.omit(userData, [
                        'type'
                    ]))

                    const lastName = user.lastName.toUpperCase()[0]
                    user.displayName = `${user.firstName} ${lastName}.`
                    user.gender = user.civility == 'M' ? 'male' : 'female'
                    user.status = 'online'
                    user.state = state

                    Storage.setCurrentUser(user)
                    resolve(user)
                })
                .catch((error) => {
                    reject(error)
                })
            })
            .catch((error) => {
                reject(error)
            })
        })
    }

    logout() {
        return new Promise((resolve, reject) => {
            const token = this.getIdToken()
            Storage.removeCurrentUser()
            const redirectLogout = encodeURI(vueAppRedirectLogout)
            const authUrl = `${clientDomain}/endsession?post_logout_redirect_uri=${redirectLogout}&id_token_hint=${token}`
            resolve(authUrl)
        })
    }

    getIdToken() {
        const user = Storage.getCurrentUser()
        if (!user) {
            return null
        }
        return _.get(user, 'auth.id_token', null)
    }

    getStoredAccessToken() {
        const user = Storage.getCurrentUser()
        if (!user) {
            return null
        }
        return _.get(user, 'auth.access_token', null)
    }

    getTokenExpirationDate(encodedToken) {
        const token = decode(encodedToken)
        if (!token.exp) {
            return null
        }
        const date = new Date(0)
        date.setUTCSeconds(token.exp)
        return date
    }

    getIdTokenExpirationDate() {
        const user = Storage.getCurrentUser()
        if (!user) {
            return null
        }
        const exp = _.get(user, 'auth.exp', null)
        const date = new Date(0)
        date.setUTCSeconds(exp)
        return date
    }

    getTokenAudience(encodedToken) {
        const token = decode(encodedToken)
        if (!token.aud) {
            return []
        }
        if (!Array.isArray(token.aud)) {
            return [token.aud]
        }
        return token.aud
    }

    getAccessTokenAudience() {
        const user = Storage.getCurrentUser()
        if (!user) {
            return null
        }
        const aud = _.get(user, 'auth.aud', null)
        if (!aud) {
            return []
        }
        if (!Array.isArray(aud)) {
            return [aud]
        }
        return aud
    }

    isIdTokenExpired() {
        return this.getIdTokenExpirationDate() < new Date()
    }

    isLoggedIn() {
        return !this.isIdTokenExpired() && this.getAccessTokenAudience().includes(tokenAudience)
    }

    checkUserRight(resident, router, loading = null) {
        if (!resident) {
            if (loading) {
                loading.close()
            }
            router.push('/')
            return false
        }
        else {
            return true
        }
    }
}

export default new Oauth()
