import _ from 'lodash'

export default {
    SET_ORDERS (state, orders) {
        state.orders = orders
    },
    SET_ORDERS_IS_REGISTERED (state, isRegistered) {
        //isRegistered: boolean
        state.isRegistered = isRegistered
    }
}
