import _ from 'lodash'
import Http from '@/http'

export default {
    getOrderById: state => (orderId) => {
        return _.find(state.orders, (order) => {
            return order.id == orderId
        })
    }
}
