import state from './categories-state.js'
import mutations from './categories-mutations.js'
import actions from './categories-actions.js'
import getters from './categories-getters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
