import { state } from './suggestions-state'
import { mutations } from './suggestions-mutations'
import { actions } from './suggestions-actions'
import { getters } from './suggestions-getters'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
