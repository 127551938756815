import Vue from 'vue'

/* Happytal custom components */
import HxTable from './components/vuesax/hx-table/HxTable.vue'
import HxTh from './components/vuesax/hx-table/HxTh.vue'
import HxPagination from './components/vuesax/hx-pagination/HxPagination.vue'
import HxSwitch from './components/vuesax/hx-switch/HxSwitch.vue'
import HxPopup from './components/vuesax/hx-popup/HxPopup.vue'
import HxDropDown from './components/vuesax/hx-dropdown/HxDropDown.vue'
import HxInput from './components/vuesax/hx-input/HxInput.vue'

Vue.component(HxTable.name, HxTable)
Vue.component(HxTh.name, HxTh)
Vue.component(HxPagination.name, HxPagination)
Vue.component(HxSwitch.name, HxSwitch)
Vue.component(HxPopup.name, HxPopup)
Vue.component(HxDropDown.name, HxDropDown)
Vue.component(HxInput.name, HxInput)
