import Http from '@/http'

const noDataFoundError = 'No data found from Ecommerce Basket API'

export default {
    getBasket ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.post(`/baskets/${payload.basketId}/quotes`)
            .then((response) => {
                const data = response.data
                if (data) {
                    var basket = data.basket || {}
                    basket.errors = {
                        products: [],
                        services: []
                    }
                    basket.variantData = data.variantData
                    basket.basketLineProducts = _.map(basket.basketLineProducts, (basketProduct) => {
                        var offerFound = {}
                        const productFound = _.find(data.products, (product) => {
                            return _.find(product.offers, (offer) => {
                                if (offer.offerId == basketProduct.offerId) {
                                    offerFound = offer
                                    return true
                                }
                                return false
                            })
                        })
                        if (!productFound) {
                            basket.errors.products.push({
                                offerId: basketProduct.offerId
                            })
                        }
                        basketProduct.meta = {
                            product: productFound ? productFound.product : null,
                            offer: offerFound
                        }
                        return basketProduct
                    })
                    basket.basketLineServices = _.map(basket.basketLineServices, (basketService) => {
                        var offerFound = {}
                        const serviceFound = _.find(data.services, (service) => {
                            return _.find(service.services, (offer) => {
                                if (offer.code == basketService.serviceCode) {
                                    offerFound = offer
                                    return true
                                }
                                return false
                            })
                        })
                        if (!serviceFound) {
                            basket.errors.services.push({
                                serviceCode: basketService.serviceCode
                            })
                        }
                        basketService.meta = {
                            serviceModel: serviceFound ? serviceFound.serviceModel : null,
                            service: offerFound
                        }
                        return basketService
                    })
                    commit('ADD_OR_UPDATE_BASKET', {
                        basket
                    })
                    resolve(basket)
                }
                else {
                    reject(noDataFoundError)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updateBasket ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const basket = payload.basket
            Http.apis.ehpad.put(`/baskets/${basket.id}`, {
                basket: {
                    ...basket,
                    basketLineProducts: basket.basketLineProducts.filter(p => p.quantity > 0),
                    basketLineServices: basket.basketLineServices.filter(s => s.quantity > 0)
                }
            })
            .then((response) => {
                commit('UPDATE_BASKET', {
                    basket
                })
                resolve(basket)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getUserBaskets ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/users/${payload.userId}/baskets?institutionId=${payload.institutionId}`).then((response) => {
                const baskets = response.data.baskets
                commit('SET_USER_BASKETS', baskets)
                commit('SET_BASKETS_IS_REGISTERED', true)
                resolve(baskets)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    ackBasket ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.post(`/residents/${payload.residentId}/baskets/${payload.basketId}/quotes/${payload.quoteId}/ack`).then((response) => {
                const baskets = response.data.baskets
                resolve(baskets)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}
