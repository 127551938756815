import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18n-data'
import Storage from '@/storage'

Vue.use(VueI18n)

var initialLocale = 'fr'
const user = Storage.getCurrentUser()
if (user && user.locale) {
    initialLocale = user.locale
}
console.log('i18n:locale', initialLocale)

export default new VueI18n({
    locale: initialLocale,
    messages: i18nData
})
