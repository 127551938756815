function stripAccents (text) {
    return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
}
function normalizeText (text) {
    if (!text) {
        return ''
    }
    return stripAccents(text.toLowerCase())
}
function capitalize (text, force = false) {
    if (!text) {
        return ''
    }
    var result = text.charAt(0).toUpperCase()
    result += force ? text.slice(1).toLowerCase() : text.slice(1)
    return result
}
function base64URLEncode (str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
}
function stripNonAscii (text) {
    return text.replace(/[^\x20-\x7E]/g, '')
}

export {
    stripAccents,
    normalizeText,
    capitalize,
    base64URLEncode,
    stripNonAscii
}
