import _ from 'lodash'
import Vue from 'vue'
import qs from 'qs'
import Router from 'vue-router'
import Oauth from '@/oauth'
import Storage from '@/storage'
import Roles from '@/constants/roles'
import Routes from '@/constants/routes'
import I18n from '@/i18n/i18n'
import Store from '@/store/store'
import {
    capitalize
} from '@/modules/strings'
import {
    getCurrentMonth,
    getNextMonth
} from '@/modules/date'
Vue.prototype.$Routes = Routes

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior (to, from, savedPosition) {
        //console.log('to', to)
        if (to.meta.keepScroll) {
            return savedPosition
        }
        return {
            x: 0,
            y: 0
        }
    },
    parseQuery (query) {
        return qs.parse(query)
    },
    stringifyQuery (query) {
        const result = qs.stringify(query)
        return result ? `?${result}` : ''
    },
    routes: [
        {
            path: '/oauth/return',
            name: 'OauthCallback',
            props: true,
            component: () => import('@/views/OauthCallback.vue'),
            meta: {
                rule: 'isGuest',
                authRequired: false
            }
        },
        {
            path: '/',
            name: Routes.Home,
            component: () => import('@/views/Home.vue'),
            meta: {
                rule: 'isGuest',
                authRequired: false,
                pageTitle: 'Accueil'
            }
        },
        {
            path: '',
            component: () => import('@/views/main/Main.vue'),
            children: [
                {
                    path: '/connexion',
                    redirect: to => {
                        const {query} = to
                        const lastRoute = query.to || null
                        const role = _.get(Store, 'state.dataUser.user.role', null)

                        switch (role) {
                            case Roles.EhpadEmployee:
                            case Roles.HappytalEmployee:
                            case Roles.Tutor:
                            case Roles.Family:
                            case Roles.EhpadManager:
                            case Roles.HappytalSupport: {
                                return lastRoute || {
                                    name: Routes.Residents
                                }
                            }
                            default:
                                return {
                                    name: Routes.Residents
                                }
                        }
                    }
                },
                {
                    path: '/residents',
                    name: Routes.Residents,
                    component: () => import('@/views/residents/Residents.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {icon: 'ClipboardIcon', title: 'Liste des résidents'}
                        ],
                        pageTitle: 'Liste des résidents',
                        rule: 'canViewAdmin',
                        authRequired: true
                    },
                    beforeEnter(to, from, next) {
                        Store.dispatch('dataResidents/updateCurrentResidentId', '')
                        next()
                    }
                },
                {
                    path: '/rubrique-aidant',
                    name: Routes.HelpCategory,
                    component: () => import('@/views/help/HelpCategory.vue'),
                    meta: {
                        pageTitle: 'Mes questions',
                        displayPageTitle: true,
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/rubrique-aidant',
                    name: Routes.ResidentHelpCategory,
                    props: true,
                    component: () => import('@/views/help/HelpCategory.vue'),
                    meta: {
                        pageTitle: 'Mes questions',
                        displayPageTitle: true,
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/rubrique-aidant/:article_id',
                    name: Routes.HelpCategoryDetail,
                    props: true,
                    component: () => import('@/views/help/HelpCategoryDetail.vue'),
                    meta: {
                        rule: 'canViewAdmin',
                        authRequired: true,
                        parent: '/rubrique-aidant',
                    }
                },
                {
                    path: '/residents/:resident_id/rubrique-aidant/:article_id',
                    name: Routes.ResidentHelpCategoryDetail,
                    props: true,
                    component: () => import('@/views/help/HelpCategoryDetail.vue'),
                    meta: {
                        rule: 'canViewAdmin',
                        authRequired: true,
                        parent: '/rubrique-aidant',
                    }
                },
                {
                    path: '/residents/:resident_id/dashboard',
                    name: Routes.ResidentDashboard,
                    props: true,
                    component: () => import('@/views/residents/Dashboard.vue'),
                    meta: {
                        parent: '/residents',
                        pageTitle: 'Accueil',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/profil',
                    name: Routes.ResidentProfile,
                    props: true,
                    component: () => import('@/views/residents/profile/Profile.vue'),
                    meta: {
                        parent: '/residents',
                        pageTitle: 'Profil',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/informations',
                    name: Routes.ResidentInformations,
                    props: true,
                    component: () => import('@/views/residents/Informations.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {
                                icon: 'ClipboardIcon',
                                url: '/residents',
                                dynamic: true,
                                getTitle: function (props) {
                                    if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                        return 'Résident'
                                    } else {
                                        return 'Liste des résidents'
                                    }
                                }
                            },
                            {
                                dynamic: true,
                                getTitle: function (props) {
                                    return props.residentname
                                }
                            }
                        ],
                        parent: '/residents',
                        pageTitle: 'Fiche Résident',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/catalogue/:category_id?',
                    name: Routes.ResidentCatalog,
                    props: true,
                    component: () => import('@/views/products/Catalog.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        keepScroll: true,
                        pageTitle: 'Catalogue'
                    }
                },
                {
                    path: '/residents/:resident_id/recherche/produits',
                    name: Routes.AlgoliaProductSearch,
                    props: true,
                    component: () => import('@/views/products/AlgoliaProductSearch.vue'),
                    meta: {
                        rule: 'canViewAdmin',
                        authRequired: true,
                        keepScroll: true,
                        pageTitle: 'Recherche de produits'
                    }
                },
                {
                    path: '/residents/:resident_id/product/:product_id',
                    name: Routes.CatalogProduct,
                    props: true,
                    component: () => import('@/views/products/Product.vue'),
                    meta: {
                        rule: 'canViewAdmin',
                        pageTitle: 'Produit du catalogue',
                        authRequired: true,
                        waitRouteLoaded: true
                    }
                },
                {
                    path: '/residents/:resident_id/product/:product_id/variant/:variant_id',
                    name: Routes.CatalogVariant,
                    props: true,
                    component: () => import('@/views/products/Product.vue'),
                    meta: {
                        rule: 'canViewAdmin',
                        pageTitle: 'Produit du catalogue (variant)',
                        authRequired: true,
                        waitRouteLoaded: true,
                        keepScroll: true
                    }
                },
                {
                    path: '/residents/:resident_id/service/:service_model_id',
                    name: Routes.CatalogService,
                    props: true,
                    component: () => import('@/views/products/Service.vue'),
                    meta: {
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Service du catalogue',
                    }
                },
                {
                    path: '/residents/:resident_id/subscriptions',
                    name: Routes.ResidentSubscriptionsCurrent,
                    props: true,
                    component: () => import('@/views/residents/subscriptions/Current.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {
                                icon: 'ClipboardIcon',
                                url: '/residents',
                                dynamic: true,
                                getTitle: function (props) {
                                    if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                        return 'Résident'
                                    } else {
                                        return 'Liste des résidents'
                                    }
                                }
                            },
                            {
                                dynamic: true,
                                getTitle: function (props) {
                                    return props.residentname
                                }
                            }
                        ],
                        parent: '/residents',
                        pageTitle: 'Abonnements',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/subscriptions/next',
                    name: Routes.ResidentSubscriptionsNext,
                    props: true,
                    component: () => import('@/views/residents/subscriptions/Next.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {
                                icon: 'ClipboardIcon',
                                url: '/residents',
                                dynamic: true,
                                getTitle: function (props) {
                                    if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                        return 'Résident'
                                    } else {
                                        return 'Liste des résidents'
                                    }
                                }
                            },
                            {
                                dynamic: true,
                                getTitle: function (props) {
                                    return props.residentname
                                }
                            }
                        ],
                        parent: '/residents',
                        pageTitle: 'Prochaine commande',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/orders',
                    name: Routes.ResidentHistory,
                    props: true,
                    component: () => import('@/views/orders/OrderHistory.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {
                                icon: 'ClipboardIcon',
                                url: '/residents',
                                dynamic: true,
                                getTitle: function (props) {
                                    if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                        return 'Résident'
                                    } else {
                                        return 'Liste des résidents'
                                    }
                                }
                            },
                            {
                                dynamic: true,
                                getTitle: function (props) {
                                    return props.residentname
                                }
                            }
                        ],
                        parent: '/residents',
                        pageTitle: 'Commandes',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/payment',
                    name: Routes.ResidentPayment,
                    props: true,
                    parent: '/residents',
                    component: () => import('@/views/residents/Payment.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {
                                icon: 'ClipboardIcon',
                                url: '/residents',
                                dynamic: true,
                                getTitle: function (props) {
                                    if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                        return 'Résident'
                                    } else {
                                        return 'Liste des résidents'
                                    }
                                }
                            },
                            {
                                dynamic: true,
                                getTitle: function (props) {
                                    return props.residentname
                                }
                            }
                        ],
                        parent: '/residents',
                        pageTitle: 'Moyen de paiement',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/services-complementaires',
                    name: Routes.ExtraServices,
                    props: true,
                    component: () => import('@/views/extra/Services.vue'),
                    meta: {
                        pageTitle: 'Services complémentaires',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/services-complementaires/:service_id',
                    name: Routes.ExtraService,
                    props: true,
                    component: () => import('@/views/extra/Service.vue'),
                    meta: {
                        pageTitle: 'Services complémentaires',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/residents/:resident_id/suggestions',
                    name: Routes.ResidentSuggestions,
                    props: true,
                    component: () => import('@/views/residents/suggestions/Suggestions.vue'),
                    redirect: () => {
                        const role = _.get(Store, 'state.dataUser.user.role', null)
                        switch (role) {
                            case Roles.EhpadEmployee:
                            case Roles.HappytalEmployee:
                            case Roles.EhpadManager:
                            case Roles.HappytalSupport:
                                return { name: Routes.ResidentSuggestionsPending }
                            case Roles.Tutor:
                            case Roles.Family:
                                return { name: Routes.ResidentSuggestionsToProcess }
                            default:
                                return '/'
                        }
                    },
                    meta: {
                        parent: '/residents',
                        pageTitle: 'Suggestions',
                        rule: 'canViewAdmin',
                        authRequired: true
                    },
                    children: [
                        {
                            path: 'pending',
                            name: Routes.ResidentSuggestionsPending,
                            component: () => import('@/views/residents/suggestions/SuggestionsPending.vue'),
                            meta: {
                                parent: '/residents',
                                pageTitle: 'Suggestions en attente de réponse',
                                rule: 'canViewAdmin',
                                authRequired: true,
                                breadcrumb: [
                                    {title: 'Home', url: '/'},
                                    {
                                        icon: 'ClipboardIcon',
                                        url: '/residents',
                                        dynamic: true,
                                        getTitle: function (props) {
                                            if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                                return 'Résident'
                                            } else {
                                                return 'Liste des résidents'
                                            }
                                        }
                                    },
                                    {
                                        dynamic: true,
                                        getTitle: function (props) {
                                            return props.residentname
                                        }
                                    }
                                ],
                            }
                        },
                        {
                            path: 'to-process',
                            name: Routes.ResidentSuggestionsToProcess,
                            props: true,
                            component: () => import('@/views/residents/suggestions/SuggestionsPending.vue'),
                            meta: {
                                parent: '/residents',
                                pageTitle: 'Suggestions à traiter',
                                rule: 'canViewAdmin',
                                authRequired: true,
                                breadcrumb: [
                                    {title: 'Home', url: '/'},
                                    {
                                        icon: 'ClipboardIcon',
                                        url: '/residents',
                                        dynamic: true,
                                        getTitle: function (props) {
                                            if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                                return 'Résident'
                                            } else {
                                                return 'Liste des résidents'
                                            }
                                        }
                                    },
                                    {
                                        dynamic: true,
                                        getTitle: function (props) {
                                            return props.residentname
                                        }
                                    }
                                ],
                            }
                        },
                        {
                            path: 'history',
                            name: Routes.ResidentSuggestionsHistory,
                            props: true,
                            component: () => import('@/views/residents/suggestions/SuggestionsHistory.vue'),
                            meta: {
                                parent: '/residents',
                                pageTitle: 'Historique des suggestions',
                                rule: 'canViewAdmin',
                                authRequired: true,
                                breadcrumb: [
                                    {title: 'Home', url: '/'},
                                    {
                                        icon: 'ClipboardIcon',
                                        url: '/residents',
                                        dynamic: true,
                                        getTitle: function (props) {
                                            if ([Roles.Tutor, Roles.Family].includes(props.userrole) && props.residents.length === 1) {
                                                return 'Résident'
                                            } else {
                                                return 'Liste des résidents'
                                            }
                                        }
                                    },
                                    {
                                        dynamic: true,
                                        getTitle: function (props) {
                                            return props.residentname
                                        }
                                    }
                                ],
                            }
                        }
                    ]
                },
                {
                    path: '/residents/:resident_id/orders/summary/:month/:year',
                    name: Routes.ResidentSummary,
                    props: true,
                    component: () => import('@/views/orders/OrderSummary.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Suivi des commandes'
                    }
                },
                {
                    path: '/residents/:resident_id/orders/:order_id',
                    name: Routes.ResidentOrder,
                    props: true,
                    component: () => import('@/views/orders/Order.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Suivi des commandes'
                    }
                },
                {
                    path: '/orders/:status',
                    name: Routes.Orders,
                    props: true,
                    component: () => import('@/views/orders/Orders.vue'),
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {
                                dynamic: true,
                                getTitle: function (props) {
                                    const params = props.route.params
                                    switch (params.status) {
                                        case 'en-cours':
                                            return getNextMonth()
                                        case 'en-livraison':
                                            return getCurrentMonth()
                                    }
                                }
                            }
                        ],
                        pageTitle: function(route) {
                            const status = route.params.status
                            switch (status) {
                                case 'en-cours':
                                    const monthNext = getNextMonth() || ''
                                    return `Suivi des commandes (${monthNext.toLowerCase()})`
                                case 'en-livraison':
                                    const monthCurrent = getCurrentMonth() || ''
                                    return `Suivi des commandes (${monthCurrent.toLowerCase()})`
                            }
                        },
                        displayPageTitle: true,
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/new-resident/:step?',
                    name: Routes.NewResident,
                    component: () => import('@/views/residents/NewResident.vue'),
                    props: true,
                    meta: {
                        breadcrumb: [
                            {title: 'Home', url: '/'},
                            {title: 'Liste des résidents', url: '/residents'},
                            {title: 'Nouveau', url: '/new-resident'}
                        ],
                        pageTitle: 'Nouveau résident',
                        rule: 'canViewAdmin',
                        authRequired: true
                    }
                },
                {
                    path: '/update-resident/:resident_id',
                    name: Routes.UpdateResident,
                    component: () => import('@/views/residents/UpdateResident.vue'),
                    props: true,
                    meta: {
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Modification résident'
                    }
                },
                {
                    path: '/residents/:resident_id/new-referant',
                    name: Routes.NewReferant,
                    component: () => import('@/views/referants/NewReferant.vue'),
                    props: true,
                    meta: {
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Nouveau tuteur / proche'
                    }
                },
                {
                    path: '/residents/:resident_id/store/basket',
                    name: Routes.ResidentStoreBasket,
                    props: true,
                    component: () => import('@/views/store/Basket.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Panier'
                    }
                },
                {
                    path: '/residents/:resident_id/store/shipping',
                    name: Routes.ResidentStoreShipping,
                    props: true,
                    component: () => import('@/views/store/Shipping.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Livraison'
                    }
                },
                {
                    path: '/residents/:resident_id/store/payment',
                    name: Routes.ResidentStorePayment,
                    props: true,
                    component: () => import('@/views/store/Payment.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Paiement'
                    }
                },
                {
                    path: '/residents/:resident_id/store/merci/:order?',
                    name: Routes.ResidentStoreThankYou,
                    props: true,
                    component: () => import('@/views/store/ThankYou.vue'),
                    meta: {
                        parent: '/residents',
                        rule: 'canViewAdmin',
                        authRequired: true,
                        pageTitle: 'Confirmation'
                    }
                },
                {
                    path: '/logistic/shipping',
                    name: Routes.LogisticShipping,
                    props: true,
                    component: () => import('@/views/logistic/Shipping.vue'),
                    meta: {
                        parent: '/logistic',
                        rule: 'canViewAdmin',
                        viewStyle: 'logistic-shipping-view',
                        authRequired: true,
                        pageTitle: 'Livraisons',
                        displayPageTitle: true
                    }
                },
                {
                    path: '/logistic/appointments',
                    name: Routes.LogisticAppointments,
                    props: true,
                    component: () => import('@/views/logistic/Appointments.vue'),
                    meta: {
                        parent: '/logistic',
                        rule: 'canViewAdmin',
                        viewStyle: 'logistic-appointments-view',
                        authRequired: true
                    }
                },
                {
                    path: '/suggestions/dashboard',
                    name: Routes.SuggestionDashboard,
                    props: true,
                    component: () => import('@/views/suggestions/Dashboard.vue'),
                    meta: {
                        parent: '/suggestions',
                        rule: 'canViewAdmin',
                        //viewStyle: 'suggestion-dashboard-view',
                        authRequired: true,
                        pageTitle: 'Suivi des suggestions',
                        displayPageTitle: true
                    }
                },
                {
                    path: '/help-center',
                    name: Routes.HelpCenter,
                    props: true,
                    component: () => import('@/views/Faq.vue'),
                    meta: {
                        pageTitle: 'Aide',
                        displayPageTitle: true,
                        rule: 'isGuest',
                        authRequired: true
                    }
                },
                {
                    path: '/user-profile',
                    name: Routes.UserProfile,
                    props: true,
                    component: () => import('@/views/user-settings/UserSettings.vue'),
                    meta: {
                        pageTitle: 'Mon compte',
                        displayPageTitle: true,
                        rule: 'isGuest',
                        authRequired: true
                    }
                }
            ]
        },
        {
            path: '',
            component: () => import('@/views/pages/FullPage.vue'),
            children: [
                {
                    path: '/catalogue',
                    name: Routes.CatalogExplorer,
                    props: true,
                    component: () => import('@/views/products/CatalogExplorer.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        keepScroll: true,
                        pageTitle: 'Catalogue'
                    }
                },
                {
                    path: '/forgot-password',
                    name: 'page-forgot-password',
                    component: () => import('@/views/ForgotPassword.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Mot de passe oublié ou activation de compte'
                    }
                },
                {
                    path: '/confirm-user',
                    name: 'page-user-confirm',
                    component: () => import('@/views/UserConfirm.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Confirmation'
                    }
                },
                {
                    path: '/user-account-expired/:user_id',
                    name: Routes.UserAccountExpired,
                    props: true,
                    component: () => import('@/views/UserAccountExpired.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Compte expiré'
                    }
                },
                {
                    path: '/forgot-password-expired/:user_id',
                    name: Routes.ForgotPasswordExpired,
                    props: true,
                    component: () => import('@/views/ForgotPasswordExpired.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Lien expiré'
                    }
                },
                {
                    path: '/error-404',
                    name: 'page-error-404',
                    component: () => import('@/views/errors/Error404.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Erreur 404'
                    }
                },
                {
                    path: '/not-authorized',
                    name: 'page-not-authorized',
                    component: () => import('@/views/NotAuthorized.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Non autorisé'
                    }
                },
                {
                    path: '/unsubscribe',
                    name: 'unsubscribe',
                    component: () => import('@/views/Unsubscribe.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Désinscription'
                    }
                },
                {
                    path: '/debug',
                    name: 'debug',
                    component: () => import('@/views/Debug.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Confirmation'
                    }
                },
            ]
        },
        {
            path: '/legal',
            component: () => import('@/views/pages/FullPageCg.vue'),
            children: [
                {
                    path: 'cgv',
                    name: 'cgv',
                    component: () => import('@/views/legal/Cgv.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: 'Conditions générales de vente'
                    }
                },
                {
                    path: 'cgu',
                    name: 'cgu',
                    component: () => import('@/views/legal/Cgu.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: `Conditions générales d’accès et d’utilisation`
                    }
                },
            ]
        },
        {
            path: '/legal',
            component: () => import('@/views/pages/TextPage.vue'),
            children: [
                {
                    path: 'notices',
                    name: 'page-legal-notices',
                    component: () => import('@/views/legal/Notices.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: ''
                    }
                },
                {
                    path: 'cookies',
                    name: 'page-legal-cookies',
                    component: () => import('@/views/legal/Cookies.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: ''
                    }
                },
                {
                    path: 'privacy',
                    name: 'page-legal-privacy',
                    component: () => import('@/views/legal/Privacy.vue'),
                    meta: {
                        rule: 'isGuest',
                        authRequired: false,
                        pageTitle: ''
                    }
                }
            ]
        },
        {
            path: '*',
            redirect: '/error-404',
            meta: {
                rule: 'isGuest',
                authRequired: false
            }
        }
    ]
})

router.beforeEach((to, from, next) => {
    // If auth required, check login.
    // If login fails redirect to login page
    const pageTitle = to.meta.pageTitle

    //console.log('from', from, 'to', to)

    // Connexion
    if (to.meta.authRequired) {
        if (!Oauth.isLoggedIn()) {
            // Remove all previous saved user auth data
            Storage.removeCurrentUser()
            // Save current route for later redirection
            const redirectUrl = _.get(to, 'fullPath', null)
            // Go to auth API login page
            Oauth.getLoginUrl(redirectUrl).then((url) => {
                window.location.href = url
            })
            return
        }
    }

    // Document title
    if (pageTitle) {
        switch (typeof pageTitle) {
            case 'string':
                document.title =  pageTitle
                break
            case 'function':
                document.title =  pageTitle(to)
                break
            default:
                document.title =  ''
        }
    }
    else {
        document.title = 'happydom'
    }

    // Handle current resident id in store
    if (_.get(to, 'params.resident_id', null)) {
        Store.dispatch('dataResidents/updateCurrentResidentId', to.params.resident_id)
    }

    // Update last route visited, needed for back button on some pages
    Store.dispatch('updateLastRouteVisited', from)

    //console.log(`route:to`, to, `route:from`, from)
    return next()
})

export default router
