import { ActionTree } from 'vuex'
import { AxiosInstance } from 'axios'

import Http from '@/http'
import {
  GetAllSuggestionsResponse,
  CreateSuggestionRequest,
  SuggestionToAddDto,
  SuggestionOpenToAddDto,
  SuggestionUserDto,
  SuggestionProductToAddDto,
  SuggestionServiceToAddDto,
  SuggestionServiceOptionToAddDto,
  SuggestionServiceOptionValueToAddDto,
  UpdateSuggestionRequest,
  SuggestionToUpdateDto
} from '@happytal/happydom-api'

import {
    formatLocalDate
} from '@/modules/date'

import Formats from '@/constants/formats'

import { State as SuggestionState } from './suggestions-state'

interface CreateOpenSuggestionPayload {
  residentId: string
  creatorName: string
  institutionId: number
  shortDescription: string
  description: string
}

interface CreateProductSuggestionPayload {
  residentId: string
  buyMode: string
  creatorName: string
  institutionId: number
  offerId: number
  frequencyInMonth?: number
  quantity: number
}

interface CreateServiceSuggestionPayload {
  residentId: string
  creatorName: string
  institutionId: number
  buyMode: string
  serviceCode: string
  frequencyInMonth?: number
  quantity: number,
  options: SuggestionServiceOptionToAddDto[]
}

interface SendSuggestionResponsePayload {
  residentId: string
  suggestionId: string
  status: string
  processedComment: string | undefined
}

interface DeleteSuggestionPayload {
  residentId: string
  suggestionId: string
}

function getOrderType(buyMode: string): string {
  switch (buyMode) {
    case 'once':
      return 'OneShot';
    case 'subscription':
      return 'Recurring';
    default:
      console.error('Order type not handled', buyMode)
      return ''
  }
}

const ephadApi: AxiosInstance = Http.apis.ehpad

export const actions: ActionTree<SuggestionState, any> = {
  async fetchAllSuggestions({ commit }, residentId: string) {
    commit('UPDATE_SUGGESTIONS_LOADING', true)
    const response = await ephadApi.get(`/residents/${residentId}/suggestions`)
    const data: GetAllSuggestionsResponse = response.data
    commit('SET_SUGGESTIONS', {
      suggestions: data.suggestions,
      productOffers: data.productOffers,
      serviceModelServices: data.serviceModelServices,
      variantData: data.variantData,
    })
    commit('UPDATE_SUGGESTIONS_LOADING', false)
  },

  async createOpenSuggestion({ dispatch, rootState }, { residentId, creatorName, institutionId, shortDescription, description }: CreateOpenSuggestionPayload) {
    const userId = rootState.dataUser.user.id
    const userDisplayName = rootState.dataUser.user.displayName
    const payload = new CreateSuggestionRequest({
      suggestion: new SuggestionToAddDto({
        institutionId,
        createdBy: new SuggestionUserDto({
          id: userId,
          name: creatorName || userDisplayName,
        }),
        suggestionOpen: new SuggestionOpenToAddDto({
          shortDescription,
          description,
        })
      })
    })
    try {
      await Http.apis.ehpad.post(`/residents/${residentId}/suggestions`, payload)
      dispatch('dataResidents/incrementResidentOpenedSuggestionCount', residentId, { root: true })
    } catch (err) {
      console.error('Unable to create open suggestion', err)
    }
  },
  async createProductSuggestion({ dispatch, rootState }, { residentId, creatorName, institutionId, buyMode, offerId, frequencyInMonth, quantity }: CreateProductSuggestionPayload) {
    const userId = rootState.dataUser.user.id
    const userDisplayName = rootState.dataUser.user.displayName
    const payload = new CreateSuggestionRequest({
      suggestion: new SuggestionToAddDto({
        institutionId,
        orderType: getOrderType(buyMode),
        createdBy: new SuggestionUserDto({
          id: userId,
          name: creatorName || userDisplayName,
        }),
        suggestionProduct: new SuggestionProductToAddDto({
          offerId,
          frequencyInMonth,
          quantity,
        })
      })
    })
    try {
      await Http.apis.ehpad.post(`/residents/${residentId}/suggestions`, payload)
      dispatch('dataResidents/incrementResidentOpenedSuggestionCount', residentId, { root: true })
    } catch (err) {
      console.error('Unable to create product suggestion', err)
    }
  },
  async createServiceSuggestion({ dispatch, rootState }, { residentId, creatorName, institutionId, buyMode, serviceCode, frequencyInMonth, quantity, options }: CreateServiceSuggestionPayload) {
    const userId = rootState.dataUser.user.id
    const userDisplayName = rootState.dataUser.user.displayName
    const payload = new CreateSuggestionRequest({
      suggestion: new SuggestionToAddDto({
        institutionId,
        orderType: getOrderType(buyMode),
        createdBy: new SuggestionUserDto({
          id: userId,
          name: creatorName || userDisplayName,
        }),
        suggestionService: new SuggestionServiceToAddDto({
          code: serviceCode,
          frequencyInMonth,
          quantity,
          options: options.map(option => new SuggestionServiceOptionToAddDto({
            ...option,
            values: option.values?.map(value => new SuggestionServiceOptionValueToAddDto({ ...value }))
          })),
        })
      })
    })
    try {
      await Http.apis.ehpad.post(`/residents/${residentId}/suggestions`, payload)
      dispatch('dataResidents/incrementResidentOpenedSuggestionCount', residentId, { root: true })
    } catch (err) {
      console.error('Unable to create service suggestion', err)
    }
  },
  async sendSuggestionResponse({ commit, dispatch, rootState}, { residentId, suggestionId, status, processedComment }: SendSuggestionResponsePayload ) {
    const user = rootState.dataUser.user
    const payload = new UpdateSuggestionRequest({
      suggestion: new SuggestionToUpdateDto({
        userId: user.id,
        status,
        processedComment
      })
    })

    try {
      await ephadApi.put(`/residents/${residentId}/suggestions/${suggestionId}/process`, payload)
      commit('UPDATE_SUGGESTION', {
        suggestionId,
        status,
        processedOnUtc: formatLocalDate(new Date(), Formats.RequestDate),
        processedBy: user,
        processedComment
      })
      dispatch('dataResidents/decrementResidentOpenedSuggestionCount', residentId,  { root: true })
    } catch (err) {
      console.error('Unable to update suggestion', err)
    }
  },
  async deleteSuggestion({ commit, dispatch, rootState}, { residentId, suggestionId }: DeleteSuggestionPayload ) {
      const user = rootState.dataUser.user
      const payload = {
        userId: user.id
      }
      await ephadApi.post(`/residents/${residentId}/suggestions/${suggestionId}/delete`, payload)
      dispatch('dataSuggestions/fetchAllSuggestions', residentId, {
          root: true
      })
      dispatch('dataResidents/decrementResidentOpenedSuggestionCount', residentId, {
          root: true
      })
    }
}
