<template>
  <th
    colspan="1"
    rowspan="1"
    @click="sortValue()">
    <div class="vs-table-text">
      <slot></slot>
      <span
        v-if="!disableSorting"
        class="sort-th">
        <vs-icon
          :icon="getSortIcon()"
          class="th-sort-icon">
        </vs-icon>
      </span>
    </div>
  </th>
</template>
<script>
export default {
  name: 'HxTh',
  props:{
    sortKey:{
      default:null,
      type: String
    },
    disableSorting: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    thIndex:0,
    thwidth: '100%',
    currentSort: 0,
    sortStatuses: [
      null,
      'asc',
      'desc'
    ]
  }),
  computed: {
    styleth () {
      return {
        width: this.thwidth
      }
    },
    parentSortStatus() {
      return this.$parent.currentSortType;
    }
  },
  methods:{
    getSortIcon () {
        switch (this.currentSort) {
            case 1:
                return 'expand_less'
            case 2:
                return 'expand_more'
            default:
                return 'unfold_more'
        }
    },
    sortValue () {
      this.currentSort = this.currentSort !== 2 ? this.currentSort + 1 : 0;
      this.$parent.sort(this.sortKey, this.sortStatuses[this.currentSort]);
    },
    resetSort() {
      this.currentSort = 0;
    }
  }
}
</script>
