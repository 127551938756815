import _ from 'lodash'

import Http from '@/http'
import {
    getI18nValue
} from '@/modules/happytalI18n'
import appsettings from '@/appsettings'

const vueAppPictureStatic = appsettings.VUE_APP_PICTURE_STATIC

export default {
    /*getProducts ({ commit }) {
        return new Promise((resolve, reject) => {
            Http.apis.static.get('/products.json').then((response) => {
                commit('SET_PRODUCTS', response.data.products)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }*/
    getProduct ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/products/${payload.productId}/?institutionId=${payload.institutionId}`)
            .then((response) => {
                const product = response.data || {}
                commit('ADD_OR_UPDATE_PRODUCT', product)
                resolve(product)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getProducts ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/products?variantCode=${payload.variantCode}&institutionId=${payload.institutionId}`)
            .then((response) => {
                const products = response.data || {}
                //commit('ADD_OR_UPDATE_VARIANT', product)
                resolve(products)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }
}
