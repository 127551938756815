import Http from '@/http'
import { sample } from 'lodash'
import { Commit, Dispatch } from 'vuex/types'
import { ServiceDto, ServiceModelOptionDto } from '@happytal/ecommerce-api'
import { EC_GetServiceResponse, EC_ServiceModel } from '@/types/api-missing'
import { ServiceOption, PayloadUpdateServiceOptionValueSelect, ServiceAsShop } from '@/types/happydom-store'

export default {
    async getService (
        { commit, dispatch }: { commit: Commit, dispatch: Dispatch },
        { serviceModelId, institutionId }: { serviceModelId: string, institutionId: string }
    ) {
        try {
            const { data }: { data: EC_GetServiceResponse } = await Http.apis.ehpad.get(`/service-models/${serviceModelId}?institutionId=${institutionId}`)
            const randomServiceCode = sample(data.services)?.code
            
            commit('ADD_OR_UPDATE_SERVICE', data)
            commit('UPDATE_SERVICE_SELECTED_CODE', randomServiceCode)
            dispatch('setOptions', data)
        } catch (error) {
            commit('NOTIFICATE_ERROR_SERVICE')
        }
    },

    setOptions (
        { commit }: { commit: Commit },
        { serviceModel, services }: { serviceModel: EC_ServiceModel, services: ServiceDto[]}
    ) {
        const options = [] as ServiceOption[]
        const mandatoryOptions = serviceModel?.publicData?.mandatoryOptions || []
        
        serviceModel?.pricing?.options?.forEach((baseOption: ServiceModelOptionDto) => {
            const option: ServiceOption = { 
                code: baseOption.code ?? '',
                label: baseOption.label ?? '',
                type: baseOption.type ?? '',
                isMandatory: baseOption.code ? mandatoryOptions.includes(baseOption.code) : false,
                values: [],
                orderConfiguration: baseOption.orderConfiguration
             }
            
            if (option.type === 'VALUE_LIST' && baseOption.availableValues?.length) {
                option.values = baseOption.availableValues.filter( availableValue => {
                    let isOptionValueExist = false

                    for (const service of services) {
                        const optionConcerned = service?.pricing?.options?.find( serviceOption=> serviceOption.code === baseOption.code )
                        const valueConcerned = optionConcerned?.values?.find( value => value.code === availableValue.code)

                        if (valueConcerned !== undefined) {
                            isOptionValueExist = true
                            break
                        }
                    }

                    return isOptionValueExist
                    
                })
            } else if (option.type === 'BOOLEAN' && baseOption.availableValues?.length) {
                option.values = baseOption.availableValues
            }

            if (option.type === 'BOOLEAN' || option.values.length) options.push(option)
        })

        commit('SET_OPTIONS', options)
        
    },

    updateBooleanOptionSelect (
        { commit }: { commit: Commit },
        payload: PayloadUpdateServiceOptionValueSelect
    ) {
        commit('UPDATE_BOOLEAN_OPTION_SELECT', payload)
    },

    updateValueListOptionSelect (
        { commit }: { commit: Commit },
        payload: PayloadUpdateServiceOptionValueSelect
    ) {
        commit('UPDATE_VALUE_LIST_OPTION_SELECT', payload)
    },

    updateShopSelectByUser (
        { commit }: { commit: Commit },
        shopCode: string
    ) {
        commit('UPDATE_SHOP_SELECT_BY_USER', true)
        commit('UPDATE_SERVICE_SELECTED_CODE', shopCode)
    },

    resetServiceState ({ commit }: { commit: Commit }) {
        commit('RESET_SERVICE_STATE')
    },

    resetOptionsSelected ({ commit }: { commit: Commit }) {
        commit('RESET_OPTIONS_SELECTED')
    }
}