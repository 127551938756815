import { stripAccents } from '@/modules/strings.js'
import _ from 'lodash'

export default {
    getProductById: state => (productId) => {
        return _.find(state.products, (product) => {
            return product.id == productId
        })
    },
    getProductsByCategoryId: (state) => (categoryId) => {
        const products = _.get(state, 'products', [])
        return _.filter(products, (product) => {
            return product.categoryId == categoryId
        })
    },
    searchProducts: (state) => (searchText, categoryIds) => {
        var results = _.get(state, 'products', [])
        if (categoryIds && categoryIds.length > 0) {
            results = _.filter(results, (product) => {
                return _.find(categoryIds, (id) => {
                    return product.categoryId == id
                })
            })
        }
        if (searchText) {
            searchText = stripAccents(searchText)
            results = _.filter(results, (product) => {
                var name = product.name ? product.name.toLowerCase() : ''
                name = stripAccents(name)
                if (name.indexOf(searchText) > -1) {
                    return true
                }
                var description = product.description ? product.description.toLowerCase() : ''
                description = stripAccents(description)
                if (description.indexOf(searchText) > -1) {
                    return true
                }
                return false
            })
        }
        return results
    },
    getCategoryIdsByType: (state) => (type) => {
        /* Type
          0 - Service
          1 - Product
        */
        var ids = []
        const products = _.get(state, 'products', [])
        products.forEach((product) => {
            if (product.type == type &&
                ids.indexOf(product.categoryId) < 0) {
                ids.push(product.categoryId)
            }
        })
        return ids
    }
}
