import state from './institutions-state.js'
import mutations from './institutions-mutations.js'
import actions from './institutions-actions.js'
import getters from './institutions-getters.js'

export default {
    isRegistered: false,
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
