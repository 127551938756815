import _ from 'lodash'
import moment from 'moment'

import {
    getResidentNameLabel
} from '@/modules/labels'

export default {
    getResidentById: state => (residentId) => {
        return _.find(state.residents, (resident) => {
            return resident.id == residentId
        })
    },
    getResidentName: (state, getters) => (id, civility = true) => {
        var residents = _.get(state, 'residents', [])
        const result = _.find(residents, (resident) => {
            return resident.id == id
        })
        return getResidentNameLabel(result, civility)
    },
    residentsNumber: state => state.residents.length
}
