import moment from 'moment'
import {getMonthLabel} from '@/modules/labels'

function getUtcNow () {
    return moment.utc().format()
}
function getUtcYear (date) {
    return moment(date).year()
}
function getUtcMonthIndex (date) {
    return moment(date).month()
}
function getTimestamp (date) {
    return moment(date).unix()
}
function getDateFromTimestamp (timestamp) {
    return moment.unix(timestamp)
}
function getDateFromString (text) {
    return moment.utc(text)
}
function formatLocalDate (dateText, format) {
    return moment.utc(dateText).local().format(format)
}
function getCurrentMonth () {
    const d = new Date()
    const monthIndex = d.getMonth()
    return getMonthLabel(monthIndex)
}
function getNextMonth () {
    const d = new Date()
    var monthIndex = d.getMonth() + 1
    if (monthIndex >= 12) {
        monthIndex = 0
    }
    return getMonthLabel(monthIndex)
}
function isChristmasPeriod() {
    const today = moment().format('MM-DD')
    return today > '11-28' && today < '12-32'
}

export {
    getUtcNow,
    getUtcYear,
    getUtcMonthIndex,
    getTimestamp,
    getDateFromTimestamp,
    getDateFromString,
    formatLocalDate,
    getCurrentMonth,
    getNextMonth,
    isChristmasPeriod
}
