<template lang="html">
  <transition name="popup-t">
    <div
      v-show="active"
      ref="con"
      :class="[`vs-popup-${color}`,{'fullscreen':fullscreen}]"
      class="vs-component con-vs-popup"
      @click="onOverlayClick">
      <div
        :style="styleCon"
        class="vs-popup--background"/>
      <div
        ref="popupx"
        :style="stylePopup"
        class="vs-popup">

        <!-- //header -->
        <header
        v-if="title"
          :style="styleHeader"
          class="vs-popup--header">
          <div class="vs-popup--title">
            <h3>{{ title }}</h3>
            <slot name="subtitle" />
          </div>
          <vs-icon
            v-if="!buttonCloseHidden"
            ref="btnclose"
            :icon-pack="iconPack"
            :icon="iconClose"
            :style="styleIcon"
            class="vs-popup--close vs-popup--close--icon"
            @click="close()"
          />
        </header>

        <!-- // slots  -->
        <div
          :style="styleContent"
          :class="classContent"
          class="vs-popup--content">
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import _color from '../color.js'
export default {
  name:'HxPopup',
  props:{
    width: {
      default:600,
      type:Number
    },
    color:{
      default:'primary',
      type:String
    },
    active: {
      default:false,
      type: Boolean
    },
    modal: {
      default:false,
      type: Boolean
    },
    title:{
      default:null,
      type:String
    },
    buttonCloseHidden:{
      default:false,
      type:Boolean
    },
    fullscreen:{
      default:false,
      type:Boolean
    },
    backgroundColor:{
      default:null,
      type:String
    },
    backgroundColorPopup:{
      default:'rgb(255,255,255)',
      type:String
    },
    styleContent:{
      default:null,
      type: String
    },
    classContent:{
      default:null,
      type: String
    },
    iconPack:{
      default:'material-icons',
      type:String
    },
    iconClose:{
      default:'close',
      type:String
    },
  },
  computed:{
    styleHeader(){
      return {
        color: _color.getColor(this.color,1),
      }
    },
    styleAfter(){
      return {
        background: _color.getColor(this.color,1)
      }
    },
    styleCon(){
      return {
        background: _color.getColor(this.backgroundColor,1)
      }
    },
    stylePopup(){
      return {
          width: `${this.width}px`,
          background: _color.getColor(this.backgroundColorPopup,1)
      }
    },
    styleIcon(){
      return {
          background: _color.getColor(this.backgroundColorPopup,1)
      }
    }
  },

  methods:{
    onOverlayClick (e) {
      if (this.modal) {
          return
      }
      if(event.target.className
          && event.target.className.indexOf
          && event.target.className.indexOf('vs-popup--background')!=-1){
        this.close()
      } else if(!this.buttonCloseHidden && event.srcElement == this.$refs.btnclose.$el){
        this.close()
      }
    },
    giveColor (color) {
      return _color.rColor(color)
    },
    close () {
      this.$emit('update:active', false)
      this.$emit('close', false)
    },
    insertBody(){
      let elx = this.$refs.con
      document.body.insertBefore(elx, document.body.firstChild)
    },
  },
  mounted(){
    this.insertBody()
  },
  beforeDestroy() {
    // close the left open prompt
    let elx = this.$refs.con
    if (document.body) {
      document.body.removeChild(elx)
    }
  }
}
</script>
