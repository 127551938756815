export default {
    WAITING_ACCEPTANCE: 'WAITING_ACCEPTANCE',
    WAITING_SCORING: 'WAITING_SCORING',
    SCORING_OK: 'SCORING_OK',
    SCORING_KO: 'SCORING_KO',
    ORDER_CLOSED: 'ORDER_CLOSED',
    ORDER_ACCEPTED: 'ORDER_ACCEPTED',
    ORDER_REFUSED: 'ORDER_REFUSED',
    ORDER_PENDING: 'ORDER_PENDING',
    ORDER_CONSUMED: 'ORDER_CONSUMED',
    ORDER_CANCELLED: 'ORDER_CANCELLED',
    ORDER_EXPIRED: 'ORDER_EXPIRED'
}
