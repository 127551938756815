import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

import moduleUser from './user/user'
import moduleResidents from './residents/residents'
import moduleTutors from './tutors/tutors'
import moduleIntitutions from './institutions/institutions'
import moduleOrders from './orders/orders'
import moduleProducts from './products/products'
import moduleService from './service/service'
import moduleCategories from './categories/categories'
import modulePcps from './pcps/pcps'
import moduleBaskets from './baskets/baskets'
import moduleForms from './forms/forms'
import moduleDrafts from './drafts/drafts'
import moduleMultiBasket from './multi-baskets/multi-baskets'
import moduleSuggestions from './suggestions/suggestions'
import moduleBuyBox from './buy-box/buy-box'
import moduleCatalogCategories from './catalog-categories/catalog-categories'
import moduleMainNav from './main-nav/main-nav'
import moduleCatalogProducts from './catalog-products/catalog-products'
import moduleAppointments from './appointments/appointments'

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        dataUser: moduleUser,
        dataResidents: moduleResidents,
        dataTutors: moduleTutors,
        dataInstitutions: moduleIntitutions,
        dataOrders: moduleOrders,
        dataProducts: moduleProducts,
        dataService: moduleService,
        dataCategories: moduleCategories,
        dataPcps: modulePcps,
        dataBaskets: moduleBaskets,
        dataForms: moduleForms,
        dataDrafts: moduleDrafts,
        dataMultiBasket: moduleMultiBasket,
        dataSuggestions: moduleSuggestions,
        dataBuyBox: moduleBuyBox,
        dataCatalogCategories: moduleCatalogCategories,
        dataMainNav: moduleMainNav,
        dataCatalogProducts: moduleCatalogProducts,
        dataAppointments: moduleAppointments
    },
    strict: process.env.NODE_ENV !== 'production'
})
