import _ from 'lodash'

export default {
    ADD_OR_UPDATE_BASKET (state, payload) {
        const basketFound = _.find(state.alls, (basket) => {
            return basket.id == payload.basket.id
        })
        if (!basketFound) {
            state.alls.push(payload.basket)
        }
        else {
            state.alls = state.alls.map((basket) => {
                if (basket.id === payload.basket.id) {
                    return payload.basket
                }
                return basket
            })
        }
    },
    UPDATE_BASKET (state, payload) {
        const basketIndex = _.findIndex(state.alls, (basket) => {
            return basket.id == payload.basket.id
        })
        state.alls[basketIndex] = payload.basket
    },
    DELETE_BASKET (state, payload) {
        _.remove(state.alls, (basket) => {
            return basket.id == payload.basketId
        })
    },
    UPDATE_PRODUCT_QUANTITY (state, payload) {
        const basketIndex = _.findIndex(state.alls, (basket) => {
            return basket.id == payload.basketId
        })
        const basket = state.alls[basketIndex]
        const productIndex = _.findIndex(basket.basketLineProducts, (basketProduct) => {
            return basketProduct.id == payload.productId
        })
        const serviceIndex = _.findIndex(basket.basketLineServices, (basketService) => {
            return basketService.id == payload.productId
        })
        if (productIndex > -1) {
            state.alls[basketIndex].basketLineProducts[productIndex].quantity = payload.quantity
        }
        else if (serviceIndex > -1) {
            state.alls[basketIndex].basketLineServices[serviceIndex].quantity = payload.quantity
        }
    },
    DELETE_PRODUCT (state, payload) {
        const basketIndex = _.findIndex(state.alls, (basket) => {
            return basket.id == payload.basketId
        })
        const basket = state.alls[basketIndex]
        if (!basket) {
          return
        }
        const productIndex = _.findIndex(basket.basketLineProducts, (basketProduct) => {
            return basketProduct.id == payload.productId
        })
        const serviceIndex = _.findIndex(basket.basketLineServices, (basketService) => {
            return basketService.id == payload.productId
        })
        if (productIndex > -1) {
            state.alls[basketIndex].basketLineProducts.splice(productIndex, 1)
        }
        else if (serviceIndex > -1) {
            state.alls[basketIndex].basketLineServices.splice(serviceIndex, 1)
        }
        /*if (state.alls[basketIndex].basketLineProducts.length < 1 &&
            state.alls[basketIndex].basketLineServices.length < 1) {
            state.alls.splice(basketIndex, 1)
        }*/
    },
    SET_USER_BASKETS (state, payload) {
        state.user = payload
    },
    SET_BASKETS_IS_REGISTERED (state, isRegistered) {
        //isRegistered: boolean
        state.isRegistered = isRegistered
    },
}
