import _ from 'lodash'
import Storage from "@/storage";

export default {
    ADD_OR_UPDATE_MULTI_BASKET (state, payload) {
        const basketFound = _.find(state.dataMultiBasket,  (basket) => {
            if (basket.resident_id == payload.basket.resident_id) {
                return basket.basket.id
            }
        })
        if (!basketFound) {
            state.dataMultiBasket.push(payload.basket)
            Storage.setMultiBasket(state.dataMultiBasket)
        }
    },
    UPDATE_MULTI_BASKET (state, payload) {
        const basketIndex = _.findIndex(state.dataMultiBasket, function(basket) { return basket.resident_id == payload.basket.resident_id})
        payload.basket.basketLineProducts = _.orderBy(payload.basket.basketLineProducts, function (e) {
            return parseInt(e.offerId);
        }, ['desc'])
        state.dataMultiBasket[basketIndex] = []
        state.dataMultiBasket[basketIndex] = payload.basket
        Storage.setMultiBasket(state.dataMultiBasket)
    },
    DELETE_MULTI_BASKET (state, payload) {
        const basketIndex = _.findIndex(state.dataMultiBasket, function(basket) { return basket.resident_id == payload.basket.resident_id})
        state.dataMultiBasket[basketIndex] = []
        Storage.setMultiBasket(state.dataMultiBasket)
    }
}
