import moment from 'moment'
import Http from '@/http'

export default {
    getOrderById ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/orders/${payload.orderId}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getCurrentOrders ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/users/${payload.userId}/current-orders?institutionId=${payload.institutionId}`).then((response) => {
                const orders = response.data.orders
                commit('SET_ORDERS', orders)
                commit('SET_ORDERS_IS_REGISTERED', true)
                resolve(orders)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    getOrders ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/residents/${payload.residentId}/orders`)
            .then((response) => {
                resolve(response.data.orders)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    searchOrders ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.post(`/orders/search`, {
                customerId: payload.customerId
            })
            .then((response) => {
                resolve(response.data.orders)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
}
