import { ServiceState } from './service-state'
import { IGetServiceModelResponse } from '@happytal/ecommerce-api'
import { ServiceOption, PayloadUpdateServiceOptionValueSelect, ServiceAsShop } from '@/types/happydom-store'

export default {
    ADD_OR_UPDATE_SERVICE(state: ServiceState, { frontCategoriesV2, services, serviceModel }: IGetServiceModelResponse) {
        state.category = frontCategoriesV2?.[0]
        state.services = services || []
        state.serviceModel = serviceModel
    },

    NOTIFICATE_ERROR_SERVICE() {
        console.error('Une erreur est survenue lors du chargement du service')
    },

    SET_OPTIONS(state: ServiceState, options: ServiceOption[]) {
        const booleanOptions = options.filter(o => o.type === "BOOLEAN")
        const valueListOptions = options.filter(o => o.type === "VALUE_LIST")

        //SET OPTIONS MODEL
        state.serviceOptions["BOOLEAN"] = booleanOptions
        state.serviceOptions["VALUE_LIST"] = valueListOptions
    },

    UPDATE_SERVICE_SELECTED_CODE(state: ServiceState, code: string) {
        state.serviceSelectedCode = code
    },

    UPDATE_BOOLEAN_OPTION_SELECT(state: ServiceState, { option, optionValue, isSelected } : PayloadUpdateServiceOptionValueSelect) {
        const storeSelectOption = state.selectedBooleanOptionValues.find(opt => opt.optionCode === option.code)

        if (storeSelectOption) {
            if (isSelected)  storeSelectOption.value = optionValue
            else state.selectedBooleanOptionValues = state.selectedBooleanOptionValues.filter(opt => opt.optionCode !== option.code)

        } else {
            state.selectedBooleanOptionValues.push({
                optionCode: option.code,
                optionLabel: option.label,
                optionOrderConfiguration: option.orderConfiguration,
                value: optionValue
            })
        }
    },

    UPDATE_VALUE_LIST_OPTION_SELECT(state: ServiceState, { option, optionValue, isSelected } : PayloadUpdateServiceOptionValueSelect) {
        const storeSelectOption = state.selectedValueListOptionValues.find(opt => opt.optionCode === option.code)

        if (storeSelectOption) {
            if (storeSelectOption?.optionOrderConfiguration?.maximumNumberOfElements === 1) {
                storeSelectOption.values = []
            }

            if (isSelected)  storeSelectOption.values.push(optionValue)
            else storeSelectOption.values = [...storeSelectOption.values.filter(val => val.code !== optionValue.code)]

            if (storeSelectOption.values.length === 0) {
                state.selectedValueListOptionValues = state.selectedValueListOptionValues.filter(opt => opt.optionCode !== option.code)
            }

        } else {
            state.selectedValueListOptionValues.push({
                optionCode: option.code,
                optionOrderConfiguration: option.orderConfiguration,
                values: [optionValue]
            })
        }
    },

    UPDATE_SHOP_SELECT_BY_USER(state: ServiceState, value: boolean) {
        state.isUserHasManifestedAChoice = value
    },

    RESET_SERVICE_STATE(state: ServiceState) {
        state.category = undefined
        state.services = []
        state.serviceModel = undefined
        state.serviceSelectedCode = ''
        state.serviceOptions.BOOLEAN = []
        state.serviceOptions.VALUE_LIST = []
        state.selectedBooleanOptionValues = []
        state.selectedValueListOptionValues = []
        state.isUserHasManifestedAChoice = false
    },

    RESET_OPTIONS_SELECTED(state: ServiceState) {
        state.selectedBooleanOptionValues = []
        state.selectedValueListOptionValues = []
    },
}
