import _ from 'lodash'

export default {
    SET_RESIDENTS (state, residents) {
        state.residents = residents
    },
    ADD_RESIDENT (state, resident) {
        state.residents.push(resident)
    },
    DELETE_RESIDENT (state, payload) {
        _.remove(state.residents, (resident) => {
            return resident.id == payload.residentId
        })
    },
    UPDATE_TUTOR (state, payload) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === payload.residentId)
        state.residents[residentIndex].userInChargeOfResident = payload.tutor
    },
    UPDATE_PAYMENT (state, payload) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === payload.residentId)
        state.residents[residentIndex].stripePaymentMethod = payload.payment
    },
    UPDATE_PCP_FILLED (state, payload) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === payload.residentId)
        state.residents[residentIndex].hasShoppingListFilled = payload.filled
    },
    UPDATE_RESIDENT (state, resident) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === resident.id)
        state.residents[residentIndex] = Object.assign(state.residents[residentIndex], resident)
    },
    INCREMENT_RESIDENT_OPENED_SUGGESTION_COUNT (state, residentId) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === residentId)
        state.residents[residentIndex].openedSuggestionCount++
    },
    DECREMENT_RESIDENT_OPENED_SUGGESTION_COUNT (state, residentId) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === residentId)
        state.residents[residentIndex].openedSuggestionCount--
    },
    RESET_RESIDENT_OPENED_SUGGESTION_COUNT(state, residentId) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === residentId)
        if (residentIndex > -1) {
            state.residents[residentIndex].openedSuggestionCount = 0
        }
    },
    REMOVE_RESIDENT (state, residentId) {
        const residentIndex = _.findIndex(state.residents, (r) => r.id === residentId)
        state.residents.splice(residentIndex, 1)
    },
    SET_RESIDENTS_IS_REGISTERED (state, isRegistered) {
        //isRegistered: boolean
        state.isRegistered = isRegistered
    },
    UPDATE_CURRENT_RESIDENT_ID (state, residentId) {
        state.currentResidentId = residentId
    }
}
