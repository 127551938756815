import StorageTypes from '@/constants/storage-types'
import StorageKeys from '@/constants/storage-keys'

class Storage {

    constructor () {
        this.clear()
    }
    clear () {
        this.user = {}
        this.verifier = ''
        this.drafts = []
        this.type = StorageTypes.LOCAL
    }
    set (key, value, json = true) {
        switch (this.type) {
            case StorageTypes.COOCKIE:
                break
            default:
                const data = json ? JSON.stringify(value) : value
                localStorage.setItem(key, data)
                break
        }
    }
    get (key, defValue = null, json = true) {
        switch (this.type) {
            case StorageTypes.COOCKIE:
                break
            default:
                const data = localStorage.getItem(key)
                if (!data) {
                    return defValue
                }
                if (json) {
                    return JSON.parse(data)
                }
                return data
        }
    }
    remove (key) {
        switch (this.type) {
            case StorageTypes.COOCKIE:
                break
            default:
                localStorage.removeItem(key);
        }
    }
    setVerifier (verifier) {
        this.verifier = verifier
        this.set(StorageKeys.VERIFIER, this.verifier, false)
    }
    getVerifier () {
        return this.get(StorageKeys.VERIFIER, null, false)
    }
    removeCurrentUser () {
        this.remove(StorageKeys.USER)
        this.remove(StorageKeys.VERIFIER)
        this.user = null
        this.verifier = null
    }
    getCurrentUser () {
        this.user = this.get(StorageKeys.USER, null)
        return this.user
    }
    setCurrentUser (user) {
        this.user = user
        console.log('storage:user', user)
        this.set(StorageKeys.USER, this.user)
    }
    getDrafts () {
        this.drafts = this.get(StorageKeys.DRAFTS, [])
        return this.drafts
    }
    setDrafts (drafts) {
        this.drafts = drafts
        this.set(StorageKeys.DRAFTS, this.drafts)
    }
    getMultiBasket () {
        this.multiBasket = this.get(StorageKeys.MULTIBASKETS, [])
        return this.multiBasket
    }
    setMultiBasket (multiBasket) {
        this.multiBasket = multiBasket
        this.set(StorageKeys.MULTIBASKETS, this.multiBasket)
    }
}

export default new Storage()
