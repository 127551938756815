import { MutationTree } from 'vuex'

import { SuggestionDto, ProductOffersDto, ServiceModelServicesDto, VariantDataDto } from '@happytal/happydom-api'

import { State as SuggestionState } from './suggestions-state'

import _ from 'lodash'
interface UpdateSuggestionPayload {
  suggestionId: string
  status: string
  processedOnUtc: Date
  processedBy: string
  processedComment: string
}

export const mutations: MutationTree<SuggestionState> = {
  SET_SUGGESTIONS(state, { suggestions, productOffers, serviceModelServices, variantData }: { suggestions: SuggestionDto[], productOffers: ProductOffersDto[], serviceModelServices: ServiceModelServicesDto[], variantData: VariantDataDto }) {
    state.suggestions = suggestions
    state.productOffers = productOffers
    state.serviceModelServices = serviceModelServices
    state.variantData = variantData
  },
  UPDATE_SUGGESTION(state, { suggestionId , ...updateSuggestionPayload}: UpdateSuggestionPayload) {
    const indexOfSuggestion = state.suggestions.findIndex( (suggestion: SuggestionDto) => suggestion.id === suggestionId)

    if (indexOfSuggestion !== -1) {
      _.merge(state.suggestions[indexOfSuggestion], updateSuggestionPayload)
    }
  },
  UPDATE_SUGGESTIONS_LOADING(state, value: boolean) {
    state.suggestionsLoading = value
  }
}
