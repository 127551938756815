import Storage from '@/storage'

export default {
    popup: {
        visible: false,
        product: null
    },
    drafts: Storage.getDrafts()
        /*{
            id: '12a28cf7-c8aa-4ff4-b810',
            userId: 'f09d6843-f04d-4989-894c-149e430c772d',
            residentId: '12a28cf7-c8aa-4ff4-b810-c263fe9bbb0d',
            type: 'once',
            products: [
                {
                    id: 1,
                    type: 'product',
                    title: `Artichauts grillés à l'huile`,
                    description: `Boite de 120g`,
                    price: 4.06,
                    frequency: 1,
                    quantity: 1,
                    meta: {}
                },
                {
                    id: 2,
                    type: 'product',
                    title: `Bouillon carottes`,
                    description: `Boite de 120g`,
                    price: 5.06,
                    frequency: 2,
                    quantity: 4,
                    meta: {}
                },
                {
                    id: 3,
                    type: 'service',
                    title: `Coiffure femme`,
                    description: `Options : Coupe, brushing...`,
                    price: 4.06,
                    frequency: 1,
                    quantity: 1,
                    meta: {}
                },
                {
                    id: 4,
                    type: 'service',
                    title: `Coiffure femme`,
                    description: `Options : Chinion...`,
                    price: 5.06,
                    frequency: 2,
                    quantity: 4,
                    meta: {}
                }
            ]
        },
        {
            id: '12a28cf7-c8aa-5fff-b810',
            userId: 'f09d6843-f04d-4989-894c-149e430c772d',
            residentId: '12a28cf7-c8aa-4ff4-b810-c263fe9bbb0d',
            type: 'subscription',
            products: [
                {
                    id: 1,
                    type: 'product',
                    title: `Artichauts grillés à l'huile`,
                    description: `Boite de 120g`,
                    price: 4.06,
                    frequency: 1,
                    quantity: 1,
                    meta: {}
                },
                {
                    id: 3,
                    type: 'service',
                    title: `Coiffure femme`,
                    description: `Options : Coupe, brushing...`,
                    price: 4.06,
                    frequency: 1,
                    quantity: 1,
                    meta: {}
                }
            ]
        }
    ]*/
}
