import _ from 'lodash'

export default {
    SET_USER (state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_USER (state, user) {
        state.user = Object.assign(state.user, user)
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_INSTITUTION (state, institutionId) {
        state.user.selectedInstitutionId = institutionId
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_ACCEPTCOMMUNICATIONS (state, acceptCommunications) {
        state.user.acceptCommunications = acceptCommunications
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_SUGGESTIONS_CONSENT (state, suggestionsNotificationsEnabled) {
        state.user.suggestionsNotificationsEnabled = suggestionsNotificationsEnabled
        localStorage.setItem('user', JSON.stringify(state.user))
    },
    UPDATE_TOURSTATUS (state, tourStatus) {
        state.user.tourStatus = {...state.user.tourStatus,...tourStatus}
        localStorage.setItem('user', JSON.stringify(state.user))
    }
}
