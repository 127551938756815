import _ from 'lodash'

export default {
    getTutorById: state => (tutorId) => {
        return _.find(state.tutors, (tutor) => {
            return tutor.id == tutorId
        })
    },
    getTutorName: (state, getters) => (tutorId) => {
        const tutor = getters.getTutorById(tutorId)
        if (!tutor) {
            return '-'
        }
        const last_name = tutor.lastName.toUpperCase()
        return `${last_name} ${tutor.firstName}`
    }
}
