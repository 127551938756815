import Http from '@/http'

export default {
    getUser ({ commit }) {
        return new Promise((resolve, reject) => {
            Http.apis.static.get('/user.json').then((response) => {
                commit('SET_USER', response.data)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    login ({ commit, state }) {
        return new Promise((resolve, reject) => {
            const user = state.user
            Http.apis.ehpad.post(`/users/${user.id}/login`).then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updateInstitution ({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            const user = state.user
            Http.apis.ehpad.put(`/users/${user.id}/selected-institution-id`, {
                institutionId: payload.institutionId
            }).then((response) => {
                commit('UPDATE_INSTITUTION', payload.institutionId)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updateConsent ({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            const user = state.user
            Http.apis.ehpad.put(`/users/${user.id}/accept-communications`, {
                acceptCommunications: payload.acceptCommunications
            }).then((response) => {
                commit('UPDATE_ACCEPTCOMMUNICATIONS', payload.acceptCommunications)
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    async updateSuggestionsConsent ({ commit, state }, payload) {
        const user = state.user
        const response = await Http.apis.ehpad.put(`/users/${user.id}/suggestions-notifications-enabled`, {
            suggestionsNotificationsEnabled: payload
        })
        commit('UPDATE_SUGGESTIONS_CONSENT', payload)
        return response
    },
    updateTourStatus ({ commit, state }, payload) {
            return commit('UPDATE_TOURSTATUS', payload)
    },

}
