import Http from '@/http'
import Roles from '@/constants/roles'

// /users?InstitutionId=1161&UserTypes=Tutor

export default {
    /*getTutors ({ commit }) {
        return new Promise((resolve, reject) => {
            Http.apis.static.get('/tutors.json').then((response) => {
                  commit('SET_TUTORS', response.data.tutors)
                  resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    }*/
    getTutors ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            Http.apis.ehpad.get(`/users?application=happydom&InstitutionId=${payload.institutionId}&UserTypes=${Roles.Tutor}&UserTypes=${Roles.Family}`)
            .then((response) => {
                  commit('SET_TUTORS', response.data.users)
                  commit('SET_TUTORS_IS_REGISTERED', true)
                  resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    createReferant ({ commit }, payload) {
        return new Promise((resolve, reject) => {
              Http.apis.ehpad.post(`/users`, {
                  user: payload.referant
              }).then((response) => {
                  const referant = response.data.user
                  commit('ADD_TUTOR', referant)
                  resolve(referant)
              })
              .catch((error) => {
                  reject(error)
              })
        })
    },
}
