import { SuggestionDto, ProductOffersDto, ServiceModelServicesDto, VariantDataDto } from '@happytal/happydom-api'

export const state = () => ({
  suggestions: [] as SuggestionDto[],
  suggestionsLoading: false,
  productOffers: [] as ProductOffersDto[],
  serviceModelServices: [] as ServiceModelServicesDto[],
  variantData: null as VariantDataDto | null,
})

export type State = ReturnType<typeof state>
