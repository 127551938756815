<template>
    <div></div>
  </template>
  
<script>
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import '../assets/css/cookieconsent.css'
import appsettings from '@/appsettings'

export default {
  methods: {
  },
  mounted() {
    if (!appsettings.VUE_APP_TEST_MODE && appsettings.VUE_APP_COOKIE_ENABLED) {
      CookieConsent.run({
        cookie: {
          name: appsettings.VUE_APP_COOKIE_NAME,
          domain: appsettings.VUE_APP_COOKIE_DOMAIN,
        },
    
        guiOptions: {
          consentModal: {
            layout: 'box',
            position: 'bottom right',
            flipButtons: false,
          },
          preferencesModal: {
            layout: 'box',
          },
        },
  
        categories: {
          necessary: {
            enabled: true,
            readOnly: true,
          },
          analytics: {
            enabled: false,
            readOnly: true,
          },
        },
  
        language: {
          default: 'fr',
          translations: {
            fr: {
              consentModal: {
                title: 'Nous respectons votre vie privée.',
                description: `
                  Nous utilisons des cookies pour améliorer votre expérience de navigation, diffuser des publicités ou des contenus personnalisés et analyser notre trafic. En cliquant sur « Accepter tout », vous consentez à notre utilisation des cookies.
                `,
                acceptAllBtn: 'Accepter tout',
                acceptNecessaryBtn: 'Refuser les cookies optionnels',
                showPreferencesBtn: 'Personnaliser mes choix',
              },
              preferencesModal: {
                title: 'Personnaliser les préférences en matière de consentement',
                savePreferencesBtn: 'Enregistrer mes choix',
                acceptAllBtn: 'Accepter tout',
                acceptNecessaryBtn: 'Refuser les cookies optionnels',
                closeIconLabel: 'Fermer',
                sections: [
                  {
                    title: 'Utilisation de cookies',
                    description: `
                      <div class="space-y-2">
                        <p>Nous utilisons des cookies pour vous aider à naviguer efficacement et à exécuter certaines fonctionnalités. Vous trouverez des informations détaillées sur tous les cookies sous chaque catégorie de consentement ci-dessous.</p>
                        <p>Les cookies qui sont catégorisés comme « nécessaires » sont stockés sur votre navigateur car ils sont essentiels pour permettre les fonctionnalités de base du site.</p>
                        <p>Nous utilisons également des cookies tiers qui nous aident à analyser la façon dont vous utilisez ce site web, à enregistrer vos préférences et à vous fournir le contenu et les publicités qui vous sont pertinents. Ces cookies ne seront stockés dans votre navigateur qu'avec votre consentement préalable.</p>
                        <p>Vous pouvez choisir d'activer ou de désactiver tout ou partie de ces cookies, mais la désactivation de certains d'entre eux peut affecter votre expérience de navigation.</p>
                      </div>
                    `,
                  },
                  {
                    title: 'Cookies nécessaires',
                    description: `
                      <div class="space-y-2">
                        <p>Les cookies nécessaires sont cruciaux pour les fonctions de base du site Web et celui-ci ne fonctionnera pas comme prévu sans eux.</p>
                        <p>Ces cookies ne stockent aucune donnée personnellement identifiable.</p>
                      </div>
                    `,
                    linkedCategory: 'necessary',
                    cookieTable: {
                      headers: {
                        name: 'Nom',
                        provider: 'Fournisseur',
                        expiration: 'Expiration',
                        description: 'Description',
                      },
                      body: [
                        {
                          name: 'cc_happydom',
                          provider: 'Happytal',
                          expiration: '6 mois',
                          description:
                            'Happytal place ce témoin pour mémoriser les préférences des utilisateurs en matière de consentement afin que leurs préférences soient respectées lors des visites ultérieures de ce site. Il ne collecte ni ne stocke aucune information personnelle sur les visiteurs du site.',
                        },
                        {
                          name: '__stripe_mid',
                          provider: 'Stripe',
                          expiration: '1 an',
                          description:
                            "Stripe place ce témoin pour éviter la fraude et évaluer le risque d'une transaction. Il ne collecte ni ne stocke aucune information personnelle sur les visiteurs du site.",
                        },
                        {
                          name: '__stripe_sid',
                          provider: 'Stripe',
                          expiration: '1 mois',
                          description:
                            "Stripe place ce témoin pour éviter la fraude et évaluer le risque d'une transaction. Il ne collecte ni ne stocke aucune information personnelle sur les visiteurs du site.",
                        },
                        {
                          name: 'idsrv',
                          provider: 'Happytal',
                          expiration: 'Session',
                          description:
                          "Happytal utilise ce cookie pour gérer les sessions d'authentification des utilisateurs sur le site, en stockant les informations de manière sécurisée et attribuant un identifiant unique crypté pour reconnaître les utilisateurs authentifiés.",
                        },
                        {
                          name: 'idsrv.session',
                          provider: 'Happytal',
                          expiration: 'Session',
                          description:
                          "Happytal utilise ce cookie pour gérer les sessions utilisateur sur le site, en stockant les informations de manière sécurisée et attribuant un identifiant unique crypté pour maintenir la session active entre les requêtes.",
                        },
                        {
                          name: '.AspNetCore.Antiforgery*',
                          provider: 'Happytal',
                          expiration: 'Session',
                          description:
                          "Happytal utilise ce cookie pour protéger contre les attaques CSRF (Cross-Site Request Forgery), en stockant un jeton de sécurité unique pour chaque session utilisateur et en vérifiant ce jeton lors des requêtes.",
                        },
                      ],
                    },
                  },
                  {
                    title: 'Cookies analytiques',
                    description: `
                      <div class="space-y-2">
                        <p>A l'heure actuelle, notre site n'utilise pas de cookies analytiques.</p>
                      </div>
                    `,
                    linkedCategory: 'analytics',
                  },
                  {
                    title: "Plus d'informations",
                    description:
                      'Pour plus d\'informations, consultez notre <a class="cc-link" href="/legal/cookies" target="blank">politique de cookies</a>.',
                  },
                ],
              },
            },
          },
        },
      })
    }
  }
}  
</script>
  