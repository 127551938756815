import _ from 'lodash'

export default {
    SET_PRODUCT_CATEGORIES (state, categories) {
        state.categories.product = categories
        state.loaded.product = true
    },
    SET_SERVICE_CATEGORIES (state, categories) {
        state.categories.service = categories
        state.loaded.service = true
    }
}
