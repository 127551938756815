import _ from 'lodash'

export default {
    ADD_OR_UPDATE_PRODUCT (state, payload) {
        const productFound = _.find(state.products, (product) => {
            return product.id == payload.product.id
        })
        if (!productFound) {
            state.products.push(payload.product)
        }
        else {
            state.products = state.products.map((product) => {
                if (product.id === payload.product.id) {
                    return payload.product
                }
                return product
            })
        }
    }
}
