import _ from 'lodash'

import ServiceStatus from '@/constants/service-status'
import ProductStatus from '@/constants/product-status'
import SuggestionStatus from '@/constants/suggestion-status'
import SuggestionTypes from '@/constants/suggestion-types'
import PaymentTypes from '@/constants/payment-types'

import {
    getProductOptionsAsHtml,
    getServiceOptionsAsHtml
} from '@/modules/services'

const monthNames = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
]
function getMonthLabel (monthIndex) {
    return monthNames[monthIndex]
}
function getYearAndMonthLabel (yearAndMonth) {
    if (!yearAndMonth) {
        return '-'
    }
    const dateParts = yearAndMonth.split('-')
    const monthName = monthNames[dateParts[1]-1]
    const year = dateParts[0]
    return `${monthName} ${year}`
}
function getFrequencyLabel (frequency) {
    switch (frequency) {
        case 1:
            return 'Tous les mois'
        case 2:
            return 'Tous les 2 mois'
        case 3:
            return 'Tous les 3 mois'
        case 4:
            return 'Tous les 4 mois'
        case 5:
            return 'Tous les 5 mois'
        case 6:
            return 'Tous les 6 mois'
    }
}
function getProductTypeLabel (type) {
    switch (type) {
        case 'product':
            return `Produits`
        case 'service':
            return `Services`
    }
}
function getGenderLabel (gender) {
    switch (gender) {
        case 'M': return 'Mr'
        case 'Mme': return 'Mme'
        default: return '-'
    }
}
function getServiceStatusLabel (status) {
    switch (status) {
        case ServiceStatus.WAITING_SCORING: return "En attente d'acceptation"
        case ServiceStatus.ORDER_CLOSED: return 'Cloturée'
        case ServiceStatus.SCORING_OK: return "En attente d'acceptation"
        case ServiceStatus.SCORING_KO: return 'Détection de fraude'
        case ServiceStatus.ORDER_ACCEPTED: return 'Acceptée'
        case ServiceStatus.ORDER_CONSUMED: return 'Réalisée'
        case ServiceStatus.WAITING_ACCEPTANCE: return "En attente d'acceptation"
        case ServiceStatus.ORDER_PENDING: return 'En attente de réalisation'
        case ServiceStatus.ORDER_REFUSED: return 'Refusée'
        case ServiceStatus.ORDER_CANCELLED: return 'Annulée'
        case ServiceStatus.ORDER_EXPIRED: return 'Expirée'
    }
}
function getProductStatusLabel (status) {
    switch (status) {
        case ProductStatus.STAGING: return "En attente d'acceptation"
        case ProductStatus.RECEIVED: return 'Livrée'
        case ProductStatus.CLOSED: return 'Cloturée'
        case ProductStatus.WAITING_DEBIT_PAYMENT:
        case ProductStatus.SHIPPING: return 'En cours de préparation'
        case ProductStatus.SHIPPED: return 'En cours de livraison'
        case ProductStatus.WAITING_ACCEPTANCE: return "En attente d'acceptation"
        case ProductStatus.INCIDENT_OPEN:
        case ProductStatus.WAITING_REFUND:
        case ProductStatus.WAITING_REFUND_PAYMENT:
        case ProductStatus.REFUNDED: return 'Incident'
        case ProductStatus.REFUSED: return 'Refusée'
        case ProductStatus.CANCELED: return 'Annulée'
    }
}
function getProductStatusTextClass(status) {
    switch (status) {
        case ProductStatus.INCIDENT_OPEN:
        case ProductStatus.WAITING_REFUND:
        case ProductStatus.WAITING_REFUND_PAYMENT:
        case ProductStatus.REFUNDED:
        case ProductStatus.REFUSED:
        case ProductStatus.CANCELED:
            return 'status-label-ko'
        default:
            return 'status-label-ok'
    }
}
function getServiceStatusTextClass(status) {
    switch (status) {
        case ServiceStatus.ORDER_REFUSED:
        case ServiceStatus.ORDER_CANCELLED:
        case ServiceStatus.ORDER_EXPIRED:
            return 'status-label-ko'
        default:
            return 'status-label-ok'
    }
}
function getSuggestionTitleLabel (suggestion) {
    return _.get(suggestion, 'suggestionProduct.title', null) ||
    _.get(suggestion, 'suggestionService.title', null) ||
    _.get(suggestion, 'suggestionOpen.shortDescription', null) || '-'
}
function getSuggestionOptionLabel(suggestion, productOffers, variantData, serviceModelServices) {
    if (!suggestion) {
        return ''
    }
    if (suggestion.suggestionProduct) {
        const productOffer = productOffers.find(po => po.offers.find(o => o.offerId === suggestion.suggestionProduct.offerId))
        if (productOffer) {
            const offer = productOffer.offers.find(o => o.offerId === suggestion.suggestionProduct.offerId)
            return getProductOptionsAsHtml({
                product: productOffer.product,
                offer: offer,
                variantInfo: {
                    data: variantData
                }
            })
        }
    }
    if (suggestion.suggestionService) {
        const serviceModelService = serviceModelServices.find(sms => sms.services.find(s => s.code === suggestion.suggestionService.code))
        if (serviceModelService) {
            const service = serviceModelService.services.find(s => s.code === suggestion.suggestionService.code)
            return getServiceOptionsAsHtml({
                meta: {
                    service,
                    serviceModel: serviceModelService.serviceModel,
                    options: suggestion.suggestionService.options
                }
            })
        }
    }
    return getSuggestionDescriptionLabel(suggestion)
}
function getSuggestionDescriptionLabel (suggestion) {
    return _.get(suggestion, 'suggestionProduct.description', null) ||
    _.get(suggestion, 'suggestionService.description', null) ||
    _.get(suggestion, 'suggestionOpen.description', null) || ''
}
function getSuggestionTypeLabel (type) {
    switch (type) {
        case SuggestionTypes.RECURRING:
        case SuggestionTypes.RECCURRING:
            return 'Abonnement'
        case SuggestionTypes.ONESHOT: return 'Une seule fois'
        case SuggestionTypes.OPEN: return 'Suggestion libre'
    }
}
function getSuggestionStatusLabel (status) {
    switch (status) {
        case SuggestionStatus.PENDING: return 'En attente'
        case SuggestionStatus.REFUSED: return 'Refusée'
        case SuggestionStatus.EXPIRED: return `Expirée`
        case SuggestionStatus.FULLFILLED: return 'Pris en charge'
        case SuggestionStatus.FULLFILLEDEXTERNALY: return 'Hors plateforme'
        case SuggestionStatus.ALREADYFULLFILLED: return 'Déjà commandé'
    }
}
function getOrderStatusLabel (order) {
    switch (order.type) {
        case 'service':
            return getServiceStatusLabel(order.status)
        case 'product':
            return getProductStatusLabel(order.status)
    }
}
function getOrderStatusTextClass (order) {
    switch (order.type) {
        case 'service':
            return getServiceStatusTextClass(order.status)
        case 'product':
            return getProductStatusTextClass(order.status)
    }
}
function getPaymentTypeLabel (type) {
    switch (type) {
        case PaymentTypes.Recurring:
        case PaymentTypes.Reccurring:
            return 'Abonnement'
        case PaymentTypes.OneShot:
            return 'Une seule fois'
        default: return '-'
    }
}
function getResidentNameLabel (resident, civility = true) {
    if (!resident) {
        return '-'
    }
    var name = ''
    if (resident.civility && civility) {
        name += getGenderLabel(resident.civility) + ' '
    }
    var lastName = _.get(resident, 'lastName', '')
    lastName = lastName.toUpperCase()
    name += `${lastName} ${resident.firstName}`
    return name
}

export {
    getMonthLabel,
    getYearAndMonthLabel,
    getFrequencyLabel,
    getProductTypeLabel,
    getGenderLabel,
    getServiceStatusLabel,
    getProductStatusLabel,
    getOrderStatusLabel,
    getPaymentTypeLabel,
    getOrderStatusTextClass,
    getProductStatusTextClass,
    getServiceStatusTextClass,
    getSuggestionTypeLabel,
    getSuggestionOptionLabel,
    getSuggestionTitleLabel,
    getSuggestionDescriptionLabel,
    getSuggestionStatusLabel,
    getResidentNameLabel
}
