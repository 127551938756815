export default {
    WAITING_ACCEPTANCE: 'WAITING_ACCEPTANCE',
    WAITING_DEBIT_PAYMENT: 'WAITING_DEBIT_PAYMENT',
    STAGING: 'STAGING',
    CLOSED: 'CLOSED',
    REFUSED: 'REFUSED',
    SHIPPING: 'SHIPPING',
    CANCELED: 'CANCELED',
    SHIPPED: 'SHIPPED',
    RECEIVED: 'RECEIVED',
    INCIDENT_OPEN: 'INCIDENT_OPEN',
    WAITING_REFUND: 'WAITING_REFUND',
    WAITING_REFUND_PAYMENT: 'WAITING_REFUND_PAYMENT',
    REFUNDED: 'REFUNDED'
}
