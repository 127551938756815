export default {
    Residents: 'Résidents',
    ResidentList: 'Liste des résidents',
    HelpCategory: 'Mes questions',
    Orders: 'Commandes',
    January: 'Janvier',
    February: 'Février',
    March: 'Mars',
    April: 'Avril',
    May: 'Mai',
    June: 'Juin',
    July: 'Juillet',
    August: 'Août',
    September: 'Septembre',
    October: 'Octobre',
    November: 'Novembre',
    December: 'Décembre',
    Dashboard: 'Tableau de bord',
    InDelivery: 'En livraison',
    NextDelivery: 'Prochaine livraison',
    Catalog: 'Catalogue',
    Products: 'Produits',
    Services: 'Services',
    Administration: 'Administration',
    Settings: 'Général',
    Users: 'Utilisateurs',
    eCommerce: 'eCommerce',
    Apps: 'Applications',
    Todo: 'Faire',
    Chat: 'Bavarder',
    Email: 'Email',
    Calendar: 'Calendrier',
    FullCalendar: 'Calendrier complet',
    SimpleCalendar: 'Calendrier simple',
    Shop: 'Boutique',
    ItemDetails: 'Détails de l\'article',
    Cart: 'Chariot',
    WishList: 'Liste de souhaits',
    Checkout: 'Check-out',
    UI: 'UI',
    DataList: 'Liste de données',
    ListView: 'Voir la liste',
    ThumbView: 'Thumb View',
    Grid: 'la grille',
    Vuexy: 'Vuexy',
    Vuesax: 'Vuesax',
    Tailwind: 'Tailwind',
    Colors: 'Couleurs',
    Card: 'Carte',
    Basic: 'De base',
    Statistics: 'Statistiques',
    Analytics: 'Analytique',
    CardActions: 'Actions de carte',
    CardColors: 'Couleurs de la carte',
    Table: 'Table',
    agGridTable: 'tableau agGrid',
    Components: 'Composants',
    Alert: 'Alerte',
    Avatar: 'Avatar',
    Badge: 'Badge',
    Breadcrumb: 'Breadcrumb',
    Button: 'Bouton',
    ButtonGroup: 'Groupe de boutons',
    Chip: 'Puce',
    Collapse: 'Effondrer',
    Dialogs: 'Dialogues',
    Divider: 'Diviseur',
    DropDown: 'Menu déroulant',
    List: 'liste',
    Loading: 'Chargement',
    Navbar: 'Barre de navigation',
    Notifications: 'Les notifications',
    Pagination: 'Pagination',
    Popup: 'Apparaitre',
    Progress: 'Le progrès',
    Sidebar: 'Barre latérale',
    Slider: 'Curseur',
    Tabs: 'Onglets',
    Tooltip: 'Info-bulle',
    Upload: 'Télécharger',
    FormsAndTable: 'Formulaires et tableau',
    FormElements: 'Éléments de formulaire',
    Select: 'Sélectionner',
    Switch: 'Commutateur',
    Checkbox: 'Case à cocher',
    Radio: 'Radio',
    Input: 'Contribution',
    NumberInput: 'Nombre d\'entrée',
    Textarea: 'Textarea',
    FormLayouts: 'Dispositions de formulaire',
    FormWizard: 'Assistant de formulaire',
    FormValidation: 'Validation du formulaire',
    FormInputGroup: 'Groupe de saisie de formulaire',
    Pages: 'Pages',
    Authentication: 'Authentification',
    Login: 'S\'identifier',
    Register: 'registre',
    ForgotPassword: 'Mot de passe oublié',
    ResetPassword: 'réinitialiser le mot de passe',
    LockScreen: 'Écran verrouillé',
    Miscellaneous: 'Divers',
    ComingSoon: 'Arrive bientôt',
    Error: 'Erreur',
    404: '404',
    500: '500',
    NotAuthorized: 'Pas autorisé',
    Maintenance: 'Entretien',
    Profile: 'Profil',
    User: 'Utilisateur',
    View: 'Vue',
    Edit: 'Modifier',
    UserSettings: 'Paramètres utilisateur',
    Invoice: 'Facture d\'achat',
    FAQ: 'FAQ',
    Search: 'Chercher',
    KnowledgeBase: 'Base de connaissances',
    ChartsAndMaps: 'Graphiques Et Cartes',
    Charts: 'Graphiques',
    ApexCharts: 'Apex Charts',
    chartjs: 'chartjs',
    echarts: 'echarts',
    chartist: 'chartist',
    GoogleMap: 'Google Carte',
    Extensions: 'Extensions',
    QuillEditor: 'Éditeur de piquants',
    DragAndDrop: 'Drag & Drop',
    Datepicker: 'Sélecteur de date',
    DatetimePicker: 'Sélecteur de date / heure',
    AccessControl: 'Contrôle d\'accès',
    I18n: 'I18n',
    Carousel: 'Carrousel',
    Clipboard: 'Presse-papiers',
    ContextMenu: 'Menu contextuel',
    StarRatings: 'Classement par étoiles',
    Autocomplete: 'Terminé automatiquement',
    Tree: 'Arbre',
    // "Import/Export": 'Importation/Exportation',
    Import: 'Importation',
    Export: 'Exportation',
    ExportSelected: 'Exporter sélectionné',
    Others: 'Autre',
    MenuLevels: 'Niveaux de menu',
    MenuLevel2p1: 'Niveau de menu 2.1',
    MenuLevel2p2: 'Niveau de menu 2.2',
    MenuLevel3p1: 'Niveau de menu 3.1',
    MenuLevel3p2: 'Niveau de menu 3.2',
    DisabledMenu: 'Menu désactivé',
    Support: 'Soutien',
    Documentation: 'Documentation',
    RaiseSupport: 'Augmenter le soutien',
    demoTitle: 'Titre de la carte',
    demoText: 'Le gâteau au sésame s\'enclenche au petit pain au pain d\'épices danois J\'adore le pain d\'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d\'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l\'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.'
}
