import { GetterTree } from 'vuex'

import { State as SuggestionState } from './suggestions-state'

import { ProductDto, SuggestionDto  } from '@happytal/happydom-api'
import { SuggestionDetailed } from '@/types/suggestions'

const sortByDate = (a: SuggestionDto, b: SuggestionDto) => {
  if ( a.createdOnUtc !== undefined && b.createdOnUtc !== undefined ) {
    if (a.createdOnUtc > b.createdOnUtc) {
      return -1
    }
    else if (a.createdOnUtc < b.createdOnUtc) {
      return 1
    }
  }
  return 0
}

export const getters: GetterTree<SuggestionState, any> = {
  productOffers: state => state.productOffers,
  serviceModelServices: state => state.serviceModelServices,
  variantData: state => state.variantData,
  suggestionsWithOfferOrService: state => {
    const suggestions: SuggestionDetailed[] = state.suggestions.map(suggestion => {

      if (suggestion.suggestionProduct) {
        const productOffer = state.productOffers.find(po => po.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId))
        const product = productOffer?.product
        const offer = productOffer?.offers?.find(o => o.offerId === suggestion.suggestionProduct?.offerId)

        return { ...suggestion, product, offer }
      }

      else if (suggestion.suggestionService) {
        const serviceModelService = state.serviceModelServices.find(sms => sms.services?.find(s => s.code === suggestion.suggestionService?.code))
        const serviceModel =  serviceModelService?.serviceModel
        const service = serviceModelService?.services?.find(s => s.code === suggestion.suggestionService?.code)

        return { ...suggestion, serviceModel, service }
      }

      return suggestion
    }) as SuggestionDetailed[]

    return suggestions
  },
  suggestionsPendingOrdered: (state, getters) => {
    const suggestions: SuggestionDetailed[] = (getters.suggestionsWithOfferOrService as SuggestionDetailed[]).filter(suggestion => suggestion.status === 'Pending')
    return suggestions.sort(sortByDate)
  },
  suggestionsClosedOrdered: (state, getters) => {
    const suggestions: SuggestionDetailed[] = (getters.suggestionsWithOfferOrService as SuggestionDetailed[]).filter(suggestion => suggestion.status !== 'Pending')
    return suggestions.sort(sortByDate)
  },
}
