import crypto from 'crypto'

function sha256 (buffer) {
    return crypto.createHash('sha256').update(buffer).digest()
}
function uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export {
    sha256,
    uuidv4
}
