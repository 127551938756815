import _ from 'lodash'

export default {
    ADD_PCP (state, payload) {
        const pcpFound = _.find(state.pcps, (pcp) => {
            return pcp.id == payload.pcp.id
        })
        if (!pcpFound) {
            state.pcps.push(payload.pcp)
        }
        else {
            state.pcps = state.pcps.map(pcp => {
                if (pcp.id === payload.pcp.id) {
                    return payload.pcp
                }
                return pcp
            })
        }
    },
    UPDATE_PCP (state, payload) {
        const pcpIndex = _.findIndex(state.pcps, (pcp) => {
            return pcp.id == payload.pcp.id
        })
        state.pcps[pcpIndex] = payload.pcp
    },
    DELETE_PCP (state, payload) {
        _.remove(state.pcps, (pcp) => {
            return pcp.id == payload.pcpId
        })
    },
    ADD_OR_UPDATE_PRODUCT (state, payload) {
        const pcpIndex = _.findIndex(state.pcps, (pcp) => {
            return pcp.id == payload.pcpId
        })
        const pcp = state.pcps[pcpIndex]
        const productIndex = _.findIndex(pcp.shoppingListProducts, (product) => {
            return product.offerId == payload.product.offerId
        })
        if (productIndex > -1) {
            state.pcps[pcpIndex].shoppingListProducts[productIndex] = payload.product
        }
        else {
            state.pcps[pcpIndex].shoppingListProducts.push(payload.product)
        }
    },
    DELETE_PRODUCT (state, payload) {
        const pcpIndex = _.findIndex(state.pcps, (pcp) => {
            return pcp.id == payload.pcpId
        })
        const pcp = state.pcps[pcpIndex]
        const productIndex = _.findIndex(pcp.products, (product) => {
            return product.offerId == payload.product.productId
        })
        state.pcps[pcpIndex].shoppingListProducts.splice(productIndex, 1)
    },
    ADD_OR_UPDATE_SERVICE (state, payload) {
        const pcpIndex = _.findIndex(state.pcps, (pcp) => {
            return pcp.id == payload.pcpId
        })
        const pcp = state.pcps[pcpIndex]
        const serviceIndex = _.findIndex(pcp.shoppingListServices, (service) => {
            return service.serviceCode == payload.service.serviceCode
        })
        if (serviceIndex > -1) {
            state.pcps[pcpIndex].shoppingListServices[serviceIndex] = payload.service
        }
        else {
            state.pcps[pcpIndex].shoppingListServices.push(payload.service)
        }
    }
}
