import _ from 'lodash'

export default {
  SET_INSTITUTIONS (state, institutions) {
    state.institutions = institutions
  },
  SET_INSTITUTIONS_IS_REGISTERED (state, isRegistered) {
    //isRegistered: boolean
    state.isRegistered = isRegistered
  }
}
