import Http from '@/http'
import moment from 'moment'

const noDataFoundError = 'No data found from Ecommerce Basket API'

export default {
    setBasket ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const institutionId = payload.draft_once.institutionId;
            const products_data = [];
            const services_data = [];
            const basket = payload.draft_once
            const services = basket.products.filter(p => p.type === 'service')
            services.forEach((item) => {
                services_data.push({
                    serviceCode: item.id,
                    quantity: item.quantity,
                    options: item.meta.options,
                    booking: {
                        type: 'DATETIME_REQUEST',
                        contactInfo: `Service pour ${payload.residentName}`,
                        timeSlots: [
                            {
                                from: new Date(),
                                to: moment().add(30, 'days').toDate()
                            }
                        ]
                    }
                })
            })
            const products = basket.products.filter(p => p.type === 'product')
            products.forEach((item) => {
                products_data.push({
                    offerId: item.meta.offer.offerId,
                    quantity: item.quantity,
                })
            })
            Http.apis.ehpad.post(`/baskets`, {
                basket: {
                    institutionId: institutionId,
                    autoGenerateQuote: true,
                    basketLineProducts: products_data,
                    basketLineServices: services_data,
                }
            })
            .then((response) => {
                const data = response.data
                data.basket.resident_id = payload.resident_id
                if (data) {
                    commit('ADD_OR_UPDATE_MULTI_BASKET', {
                        basket: data.basket
                    })
                    resolve(data)
                }
                else {
                    reject(noDataFoundError)
                }
            })
            .catch((error) => {
                reject(error)
            })
        })
    },
    updateBasket ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            const institutionId = payload.draft_once.institutionId;
            const products_data = [];
            const services_data = [];
            const basketId = payload.currentBasket.id
            const basket = payload.draft_once
            const services = basket.products.filter(p => p.type === 'service')
            services.forEach((item) => {
                let shippingTypeCode = null
                if(item.shippingTypeCode) {
                    shippingTypeCode = item.shippingTypeCode
                }
                let deliveryDate = null
                if(item.deliveryDate) {
                    deliveryDate = item.deliveryDate
                }
                services_data.push({
                    serviceCode: item.id,
                    quantity: item.quantity,
                    options: item.meta.options,
                    shippingTypeCode: shippingTypeCode,
                    deliveryDate: deliveryDate,
                    booking: {
                        type: 'DATETIME_REQUEST',
                        contactInfo: `Service pour ${payload.residentName}`,
                        timeSlots: [
                            {
                                from: new Date(),
                                to: moment().add(30, 'days').toDate()
                            }
                        ]
                    }
                })
            })
            const products = basket.products.filter(p => p.type === 'product')
            products.forEach((item) => {
                let shippingTypeCode = null
                if(item.shippingTypeCode) {
                    shippingTypeCode = item.shippingTypeCode
                }
                let deliveryDate = null
                if(item.deliveryDate) {
                    deliveryDate = item.deliveryDate
                }
                products_data.push({
                    offerId: item.meta.offer.offerId,
                    quantity: item.quantity,
                    shippingTypeCode: shippingTypeCode,
                    deliveryDate: deliveryDate
                })
            })
            Http.apis.ehpad.put(`/baskets/` + basketId, {
                basket: {
                    institutionId: institutionId,
                    autoGenerateQuote: true,
                    basketLineProducts: products_data,
                    basketLineServices: services_data,
                }
            })
                .then((response) => {
                    const data = response.data
                    data.resident_id = payload.resident_id
                    if (data) {
                        commit('UPDATE_MULTI_BASKET', {
                            basket: data
                        })
                        resolve(data)
                    }
                    else {
                        reject(noDataFoundError)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}
