class Http {

    constructor () {
        this.apis = {}
    }
    addApi (name, axios) {
        this.apis[name] = axios
    }
    getApiFromName (name) {
        return this.apis[name]
    }
    setBearerToken (name, token) {
        this.apis[name].defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
}

export default new Http()
