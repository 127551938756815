import _ from 'lodash'

export default {
    getFormById: (state) => (formId) => {
        return state.forms[formId] || null
    },
    getFormFieldValue: (state, getters) => (formId, name) => {
        const form = getters.getFormById(formId)
        return form ? form[name] : null
    }
}
