import Vue from 'vue'
import {
    AclInstaller,
    AclCreate,
    AclRule
} from 'vue-acl'

import router from '@/router'
import Storage from '@/storage'
import Roles from '@/constants/roles'

Vue.use(AclInstaller)

let initialRole = Roles.Guest

const user = Storage.getCurrentUser()
if (user && user.role) {
    initialRole = user.role
}

export default new AclCreate({
    initial: initialRole,
    notfound: '/not-authorized',
    router,
    acceptLocalRules: true,
    globalRules: {
        /* User role based rules */
        isGuest: new AclRule(Roles.Guest)
            .or(Roles.Tutor)
            .or(Roles.Family)
            .or(Roles.EhpadManager)
            .or(Roles.EhpadEmployee)
            .or(Roles.HappytalEmployee)
            .or(Roles.HappytalSupport)
            .or(Roles.HappytalProvider)
            .or(Roles.Provider)
            .generate(),
        isEhpadEmployee: new AclRule(Roles.EhpadEmployee).generate(),
        isHappytalEmployee: new AclRule(Roles.HappytalEmployee).generate(),
        isTutor: new AclRule(Roles.Tutor).generate(),
        isFamily: new AclRule(Roles.Family).generate(),
        isEhpadManager: new AclRule(Roles.EhpadManager).generate(),
        isHappytalSupport: new AclRule(Roles.HappytalSupport).generate(),
        isProvider: new AclRule(Roles.Provider).generate(),
        isHappytalProvider: new AclRule(Roles.HappytalProvider).generate(),
        /* Interface based rules */
        canViewAdmin: new AclRule(Roles.EhpadEmployee)
            .or(Roles.HappytalEmployee)
            .or(Roles.Tutor)
            .or(Roles.Family)
            .or(Roles.EhpadManager)
            .or(Roles.HappytalSupport)
            .generate(),
        canEmitSuggestions: new AclRule(Roles.EhpadEmployee)
            .or(Roles.EhpadManager)
            .generate(),
        canReceiveSuggestions: new AclRule(Roles.Tutor)
            .or(Roles.Family)
            .or(Roles.HappytalEmployee)
            .or(Roles.HappytalSupport)
            .generate(),
        canUpdateResident: new AclRule(Roles.EhpadManager)
            .or(Roles.EhpadEmployee)
            .or(Roles.HappytalEmployee)
            .or(Roles.HappytalSupport)
            .generate()
    }
})
